const TO_HEX = new Array(256);
for (let i=0; i<256; i++) {
    let s = i.toString(16);
    if (s.length === 1)
        s = "0" + s;
    TO_HEX[i] = s;
}

//Most common case is for SHA1 hashes, which are 20 bytes
var tmpArr20 = new Array(20);

var tmpArr10 = new Array(10);

//Converts the input byte array into a string of half the length
//by packing two bytes into each string character (JS strings are two bytes per char)
export function binToPackedString(buffer, offset, length) {
    var res = (length === 20) ? tmpArr10 : [];

    for (var i=0; i<length; i+=2) {
        var b0 = buffer[offset+i];
        var b1 = buffer[offset+i+1];
        res[i/2] = b1 << 8 | b0;
    }

    return String.fromCharCode.apply(null, res);
}

// like binToPackedString, but requires a Uint16Array, and always reads 20 bytes
export function bin16ToPackedString(buffer, offset=0) {
    return String.fromCharCode(
        buffer[offset],
        buffer[offset + 1],
        buffer[offset + 2],
        buffer[offset + 3],
        buffer[offset + 4],
        buffer[offset + 5],
        buffer[offset + 6],
        buffer[offset + 7],
        buffer[offset + 8],
        buffer[offset + 9]
    );
}

//Converts from UCS16 packed string (two bytes per character) to
//regular ASCII string of 4x the length
export function unpackHexString(s) {
    var res = (s.length === 10) ? tmpArr20 : [];

    for (var i=0; i<s.length; i++) {
        var bytes = s.charCodeAt(i);
        res[2*i] = TO_HEX[bytes & 0xff];
        res[2*i+1] = TO_HEX[(bytes >> 8) & 0xff];
    }

    return res.join("");
}

export function packedToBin(str, buf, offset) {
    for (let i=0; i<str.length; i++) {
        let bytes = str.charCodeAt(i);
        buf[offset+2*i] = bytes & 0xff;
        buf[offset+2*i+1] = (bytes >> 8) & 0xff;
    }

}

/**
 * Digest a string
 *
 * @param {string} algorithm - Hash function to use, supported are `SHA-1` and `SHA-256`
 * @param {string|ArrayBuffer} str - String to digest
 * @param {boolean} asHexString - If true, returns a digest in a form of a hex string; default is false
 * @returns {Promise<string|ArrayBuffer>} A promise that resolves with a digest of the string
 */
export async function digest(algorithm, str, asHexString = false) {
    if (typeof crypto?.subtle?.digest !== 'undefined') {
        const digest = await crypto.subtle.digest(algorithm, new TextEncoder('utf-8').encode(str));
        if (!asHexString) return digest;
        const hashArray = Array.from(new Uint8Array(digest));
        return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    }
    // a fallback for unsecure contexts
    const forge = require('node-forge');
    let alg;
    switch (algorithm) {
        case 'SHA-1':
            alg = forge.md.sha1.create();
            break;
        case 'SHA-256':
            alg = forge.md.sha256.create();
            break;
        default:
            console.error("Hash function is not supported");
            return asHexString ? '' : new ArrayBuffer(0);
    }
    alg.update(str, 'utf8');
    const digest = alg.digest();
    if (asHexString) return digest.toHex();
    return Uint8Array.from(digest.getBytes(), c => c.charCodeAt(0)).buffer;
}