import { VERTEX_BUFFER_REGION_SIZE, INDEX_BUFFER_REGION_SIZE } from '../globals.js';
import sortedIndexBy from 'lodash/sortedIndexBy';

/**
 * Represents a list of free memory blocks.
 */
class FreeMemoryList {

    /**
     * Creates a new free memory list.
     */
    constructor() {
        /** @type {BufferSubset[]} */
        this._subsets = new Array();
        this._totalFreeSpace = 0;
    }

    /**
     * Inserts a new block into the free memory list.
     * @param {BufferSubset} block - The block to insert.
     */
    insert(block) {
        let index = sortedIndexBy(this._subsets, block, 'size');
        this._totalFreeSpace += block.size;
        this._subsets.splice(index, 0, block);
    }

    /**
     * Removes a block from the free memory list.
     * @param {BufferSubset} block - The block to remove.
     */
    remove(block) {
        this._totalFreeSpace -= block.size;
        this._subsets.splice(this._subsets.indexOf(block), 1);
    }

    /**
     * Returns the block with the smallest size that is larger or equal to the given size.
     * @param {number} size - The size to search for.
     */
    findSmallestLargerOrEqual(size) {
        let index = sortedIndexBy(this._subsets, { size }, 'size');
        if (index < this._subsets.length) {
            return this._subsets[index];
        }
        return null;
    }

    /**
     * Returns the largest free block size.
     * @returns {number} The largest free block size.
     */
    getLargestFreeSubsetSize() {
        return this._subsets.length > 0 ? this._subsets[this._subsets.length - 1].size : 0;
    }

    /**
     * Returns the total free space in the list.
     * @returns {number} The total free space in the list.
     */
    get totalFreeSpace() {
        return this._totalFreeSpace;
    }
}

/**
 * Information about a subset of a larger webgl buffer that is used by a LeanBufferGeometry.
 */
export class BufferSubset {
    /**
     * Creates a new buffer subset.
     *
     * @param {BufferRegion} bufferRegion - Buffer region that contains this subset.
     * @param {number} offset - Offset of the subset in the buffer region.
     * @param {number} size - Size of the subset in bytes.
     */
    constructor(bufferRegion, offset, size) {
        this.size = size;
        this.offset = offset;
        this.free = false;
        this.next = null;
        this.prev = null;
        this.bufferRegion = bufferRegion;
    }

    /**
     * Returns the WebGL buffer that contains this subset.
     * @returns {WebGLBuffer} The WebGL buffer that contains this subset.
     */
    getGlBuffer() {
            return this.bufferRegion._buffer;
    }

    /**
     * Returns the stride of this subset
     *
     * @returns {number} The stride of the buffer subset.
     */
    getStride() {
            return this.bufferRegion._stride;
    }
}


/**
 * Represents one region that has been allocated on the GPU which contains multiple individual buffers.
 */
class BufferRegion {
    /**
     * Creates a new buffer region.
     *
     * @param {WebGLRenderingContext} gl - The WebGL context.
     * @param {number} size - The size of the buffer region in bytes.
     * @param {number} type - The type of the buffer (gl.ARRAY_BUFFER or gl.ELEMENT_ARRAY_BUFFER)
     * @param {number} stride - The stride of the buffer region.
     */
    constructor(gl, size, type, stride) {
        this._buffer = gl.createBuffer();
        this._size = size;
        this._allocatedSize = 0;

        // Points to the first subset in the list.
        this._firstSubset = null;
        // The pointer to the last subset in the list is only used during bump allocation.
        this._lastSubset = null;
        this._freeList = new FreeMemoryList();
        this._bumpAllocationDone = false;

        this._isVertexBuffer = type === gl.ARRAY_BUFFER;
        this._stride = stride;

        gl.bindBuffer(type, this._buffer);
        gl.bufferData(type, size, gl.STATIC_DRAW);
    }

    isEmpty() {
        return (this._freeList.getLargestFreeSubsetSize() === this._size);
    }

    /**
     * Tries to allocate a buffer subset of the given size.
     * If there is a gap large enough to fit the new subset, the space will be used.
     * Otherwise, allocation will fail.
     *
     * @param {number} size - The size of the buffer subset.
     * @returns {BufferSubset|null} - The newly allocated buffer subset or null on failure.
     */
    tryAllocateBufferSubset(size) {

        // On initial allocation, allocations are done in a bump allocation style since we know that
        // there is no fragmentation yet. This is much faster than the general case.
        if (!this._bumpAllocationDone) {
            // When we are doing bump allocation, we know that the allocated size + size is the indicator
            // that tells us if there is enough space left, because while doing bump allocations the subsets
            // are added consecutively.
            if (this._allocatedSize + size > this._size) {
                return null;
            }

            let subset = null;
            // On the first allocation, we need to check if the first subset is null, if so we need to create
            // a new subset and set it as the first subset.
            if (this._firstSubset === null) {
                subset = new BufferSubset(this, 0, size);
                this._firstSubset = subset;
                this._lastSubset = this._firstSubset;
            } else {
                subset = new BufferSubset(this, this._allocatedSize, size);
                this._lastSubset.next = subset;
                subset.prev = this._lastSubset;
                this._lastSubset = subset;
            }
            this._allocatedSize += size;
            return subset;
        }

        // Early exit if the requested size is larger than the largest free subset.
        if (size > this._freeList.getLargestFreeSubsetSize()) {
            return null;
        }

        let freeSubset = this._freeList.findSmallestLargerOrEqual(size);

        // Since the free list is sorted by size, we need to remove the free subset from the list, even
        // though we might insert it later again with a smaller size.
        this._freeList.remove(freeSubset);
        const newSubset = new BufferSubset(this, freeSubset.offset, size);
        newSubset.next = freeSubset.next;
        newSubset.prev = freeSubset.prev;

        if (freeSubset.prev) {
            freeSubset.prev.next = newSubset;
        }

        // We need to check if there is still space left in the remainder subset,
        // if so we need to insert it into the free list and update the next pointer.
        if (freeSubset.size > 0) {
            freeSubset.size -= size;
            freeSubset.prev = newSubset;
            freeSubset.offset += size;
            this._freeList.insert(freeSubset);
            newSubset.next = freeSubset;
        } else {
            if (freeSubset.next) {
                freeSubset.next.prev = newSubset;
            }
        }

        this._allocatedSize += size;
        return newSubset;
    }

    /**
     * Destroys the buffer region and frees the GPU memory.
     * @param {WebGLRenderingContext} gl - The WebGL context.
     */
    destroy(gl) {
            gl.deleteBuffer(this._buffer);
            this._buffer = null;
    }

    /**
     * Frees a buffer subset and merges the freed space with adjacent free blocks if possible.
     * @param {BufferSubset} subset - The buffer subset to free.
     */
    freeBufferSubset(subset) {

        this._bumpAllocationDone = true;

        const subsetSize = subset.size;
        subset.free = true;

        // Merge with next subset
        if (subset.next && subset.next.free) {
            this._freeList.remove(subset.next);
            subset.size += subset.next.size;
            subset.next = subset.next.next;
            if (subset.next) {
                subset.next.prev = subset;
            }
        }

        // Merge with previous subset
        if (subset.prev && subset.prev.free) {
            this._freeList.remove(subset.prev);
            subset.size += subset.prev.size;
            subset.offset = subset.prev.offset;
            subset.prev = subset.prev.prev;
            if (subset.prev) {
                subset.prev.next = subset;
            }
        }

        this._freeList.insert(subset);
        this._allocatedSize -= subsetSize;
    }
}

/**
 * This class manages WebGl vertex and index buffers. It combines multiple buffers into a single buffer
 * and keeps track of the offsets of each buffer. This is done to make memory management on the GPU
 * more efficient.
 */
export class BufferManager {
    static BufferManagers = [];
    /**
     * Creates a Buffer manager instance
     * @param {WebGLRenderingContext} gl - WebGl context
     */
    constructor(gl) {
        this._gl = gl;
        this._indexBuffers = [];
        this._vertexBuffers = [];
        this._exactBuffers = [];
        /** @type {BufferSubset[]} */ this._recycledBufferSubsets = [];

        // Register this buffer manager to support memory statistics
        if (typeof WeakRef !== 'undefined') {
            BufferManager.BufferManagers.push(new WeakRef(this));
        }
    }

    /**
     * Allocates a new buffer region of the given type.
     * @param {number} type - The type of the buffer (gl.ARRAY_BUFFER or gl.ELEMENT_ARRAY_BUFFER)
     * @param {number} minSize - The minimum size of the buffer region.
     * @param {number} stride - The stride of the buffer region.
     * @param {boolean} useExactSize - Allocate a chunk with exactly the provided size
     * @returns {BufferRegion} - The newly allocated buffer region.
     */
    allocateNewBufferRegion(type, minSize, stride, useExactSize = false) {
        const isVertexBuffer = type === this._gl.ARRAY_BUFFER;
        const allocatedBuffers = isVertexBuffer ? this._vertexBuffers : this._indexBuffers;
        const bufferSize = useExactSize ? minSize : Math.max(minSize, isVertexBuffer ? VERTEX_BUFFER_REGION_SIZE : INDEX_BUFFER_REGION_SIZE);

        console.assert(bufferSize % 4 === 0, "Buffer size must be a multiple of 4");

        const newBuffer = new BufferRegion(this._gl, bufferSize, type, stride);
        if (!useExactSize) {
            allocatedBuffers.push(newBuffer);
        } else {
            newBuffer.exact = true;
            this._exactBuffers.push(newBuffer);
        }
        return newBuffer;
    }

    /**
     * Tries to allocate a buffer subset of the given size and stride.
     * If no buffer region with the given stride and enough free space
     * exists, a new one will be allocated.
     *
     * @param {number} size - The size of the buffer subset.
     * @param {number} type - The type of the buffer (gl.ARRAY_BUFFER or gl.ELEMENT_ARRAY_BUFFER)
     * @param {number} stride - The stride of the buffer subset.
     * @returns {BufferSubset} - The newly allocated buffer subset.
     */
    allocateBufferSubset(size, type, stride, forceNewBuffer = false) {
        const isVertexBuffer = type === this._gl.ARRAY_BUFFER;
        const allocatedBuffers = isVertexBuffer ? this._vertexBuffers : this._indexBuffers;

        size += (size %4) !== 0 ? 4 - (size %4) : 0; // Align to 4 bytes

        // Search through list of buffers and try to allocate a subset in one of them.
        // Note: this does a linear search from the beginning of the buffer list. We
        // could potentially optimize this by keeping information about the available
        // free spaces in the buffers.
        // However, at the moment, this does not seem to be a large performance bottleneck,
        // during uploading only 2.7% of the time had been spent in this function.
        if (!forceNewBuffer) {
            for (let i = 0; i < allocatedBuffers.length; i++) {
                if (allocatedBuffers[i]._stride === stride) {
                        const subset = allocatedBuffers[i].tryAllocateBufferSubset(size);
                        if (subset) {
                                return subset;
                        }
                }
            }
        }

        // If no buffer has enough space, allocate a new one.
        const newBuffer = this.allocateNewBufferRegion(type, size, stride, forceNewBuffer);
        return newBuffer.tryAllocateBufferSubset(size);
    }

    /**
     * Frees a buffer subset.
     * If the buffer region that contains the subset is empty after freeing the subset,
     * it will be destroyed.
     *
     * @param {BufferSubset} subset - The buffer subset to free.
     */
    freeBufferSubset(subset) {
        const freedSize = subset.size;
        subset.bufferRegion.freeBufferSubset(subset);

        // If the buffer region is empty, remove it from the list of allocated buffers and destroy it.
        if (subset.bufferRegion.isEmpty()) {
                const isVertexBuffer = subset.bufferRegion._isVertexBuffer;
                const allocatedBuffers = subset.bufferRegion.exact ? this._exactBuffers : (isVertexBuffer ? this._vertexBuffers : this._indexBuffers);
                allocatedBuffers.splice(allocatedBuffers.indexOf(subset.bufferRegion), 1);
                subset.bufferRegion.destroy(this._gl);
        }
        return freedSize;
    }

    /**
     * Remove all buffers from the cache (since those were all deallocated when the context was lost)
     */
    resetAfterContextLoss() {
        this._vertexBuffers = [];
        this._indexBuffers = [];
        this._exactBuffers = [];
    }

    /**
     * Prints some statistics about the memory usage of the buffer manager.
     */
    printMemoryStatistics() {
        console.log("Vertex Buffer Count: ", this._vertexBuffers.length);
        console.log("Memory in Vertex Buffers: ", this._vertexBuffers.reduce( (a,b) => {
            this._gl.bindBuffer(this._gl.ARRAY_BUFFER, b._buffer);
            const size = this._gl.getBufferParameter(this._gl.ARRAY_BUFFER, this._gl.BUFFER_SIZE);
            return a + size;
        }, 0) / 1024);
        console.log("Actually allocated memory in Vertex Buffers: ", this._vertexBuffers.reduce( (a,b) => {
                return a + b._size - b._freeList.totalFreeSpace;
        }, 0));

        console.log("Index Buffer Count: ", this._indexBuffers.length);
        console.log("Memory in Index Buffers: ", this._indexBuffers.reduce( (a,b) => {
                this._gl.bindBuffer(this._gl.ELEMENT_ARRAY_BUFFER, b._buffer);
                const size = this._gl.getBufferParameter(this._gl.ELEMENT_ARRAY_BUFFER, this._gl.BUFFER_SIZE);
                return a + size;
        }, 0) / 1024);

        console.log("Actually allocated memory in Index Buffers: ", this._indexBuffers.reduce( (a,b) => {
                return a + b._size - b._freeList.totalFreeSpace;
        }, 0));
    }

    getMemoryStatistics() {
        return {
            vertexBufferCount: this._vertexBuffers.length,
            vertexBufferMemory: this._vertexBuffers.reduce( (a,b) => {
                return a + b._size;
            }, 0),
            vertexBufferAllocatedMemory: this._vertexBuffers.reduce( (a,b) => {
                    return a + b._allocatedSize;
            }, 0),
            indexBufferCount: this._indexBuffers.length,
            indexBufferMemory: this._indexBuffers.reduce( (a,b) => {
                    return a + b._size;
            }, 0),
            indexBufferAllocatedMemory: this._indexBuffers.reduce( (a,b) => {
                    return a + b._allocatedSize;
            }, 0),
            exactBufferCount: this._exactBuffers.length,
            exactBufferMemory: this._exactBuffers.reduce( (a,b) => {
                    return a + b._size;
            }, 0),
            exactBufferAllocatedMemory: this._exactBuffers.reduce( (a,b) => {
                    return a + b._allocatedSize;
            }, 0)
        };
    }

    dtor() {
        this._vertexBuffers.forEach(x => x.destroy(this._gl));
        this._vertexBuffers = [];
        this._indexBuffers.forEach(x => x.destroy(this._gl));
        this._indexBuffers = [];
        this._exactBuffers.forEach(x => x.destroy(this._gl));
        this._exactBuffers = [];

            if (typeof WeakRef !== 'undefined') {
                let index = BufferManager.BufferManagers.findIndex(x => x.deref() === this);
                if (index !== -1) {
                    BufferManager.BufferManagers.splice(index, 1);
                }
            }
    }

    static getTotalMemoryStatistics() {
        let totalMemory = 0;
        let totalAllocatedMemory = 0;
        for (let i = 0; i < BufferManager.BufferManagers.length; i++) {
            const bufferManager = BufferManager.BufferManagers[i].deref();
            if (bufferManager) {
                let stats = bufferManager.getMemoryStatistics();
                totalMemory += stats.vertexBufferMemory + stats.indexBufferMemory + stats.exactBufferMemory;
                totalAllocatedMemory += stats.vertexBufferAllocatedMemory + stats.indexBufferAllocatedMemory + stats.exactBufferAllocatedMemory;
            }
        }

        return {
            totalMemory,
            totalAllocatedMemory,
        };
    }
}