
import { isNodeJS } from '../../compat';
import { getResourceUrl } from '../../globals';
import { BUILD_FLAG__LMV_WORKER_FILE } from '../../buildFlags.js';
import { ViewingService } from '../../file-loaders/net/Xhr';

class WorkerCreator {

    //Those are globals -- set by the build system.
    LMV_WORKER_URL = BUILD_FLAG__LMV_WORKER_FILE || "src/file-loaders/workers/MainWorker-web.js";

    // A cache of entire worker script as data URL.
    WORKER_DATA_URL = null;

    // Externally added scripts that are imported by workers.
    customWorkerScriptURLs = [];

    /**
     * Prefetch the worker script.
     */
    initWorkerScript() {
        fetch(getResourceUrl(this.LMV_WORKER_URL));
    }

    /**
     * Add a custom script to be imported by the workers.
     * Must be called before any workers are created to have an effect.
     * @param {string} scriptURL - The URL of the script to import.
     */
    addCustomWorkerScript(scriptURL) {
        if (this.WORKER_DATA_URL) {
            console.warn("Called addCustomWorkerScript after a worker has been created. The call is ignored.");
        } else {
            this.customWorkerScriptURLs.push(scriptURL);
        }
    }

    /**
     * Create a web worker.
     * @param {string} name - Arbitrary name of the worker for debugging, shows up e.g. in chrome profiler.
     */
    createWorker(name, needForwardProtocolHandler) {

        // idea from https://github.com/webpack/webpack/discussions/14648#discussion-3666804
        // We cannot construct the worker directly from the URL because CORS.
        // In particular, when consuming LMV from a CDN, the worker script is not in the origin of the page.
        if (!this.WORKER_DATA_URL) {
            // getResourceUrl might return a relative path (iff options.lmvResourceRoot is set to a relative URL, which ACC does).
            // importScripts does not work with relative paths in workers created with a blob: uri. So we construct a new absolute URL here.
            // We use document.baseURI because that is what fetch/XmlHttpRequest/Worker() would use if you give them a relative URL.
            // If you pass in an absolute URL; the second URL() parameter is ignored.
            const workerScriptURL = new URL(getResourceUrl(this.LMV_WORKER_URL), document.baseURI).href;
            const allScriptURLs = [workerScriptURL].concat(this.customWorkerScriptURLs);
            // Make a parameter list: ["url1", "url2", ...] -> '"url1", "url2", ...'
            // As strings are evaluatated as code, they are escaped via JSON.stringify.
            const listOfScripts = allScriptURLs.map(x => JSON.stringify(x)).join(",");

            const blob = new Blob(
                [`
                try {
                    importScripts(${listOfScripts});
                } catch (e) {
                    console.warn("Failed to import worker script. Could be a network failure or just the worker closing.");
                    console.log(e);
                }
            `],
                {
                    type: "application/javascript",
                },
            );
            this.WORKER_DATA_URL = URL.createObjectURL(blob);
        }
        let w = new Worker(this.WORKER_DATA_URL, { name });

        w.doOperation = w.postMessage;

        if (needForwardProtocolHandler === true) {
            ViewingService.forwardProtocolHandlerToWorker(w);
        }

        return w;
    }

}

//Node.js case -- the web worker is a "fake" worker running on the main thread.
class WorkerCreatorNode extends WorkerCreator {

    constructor() {
        super();

        this.MainWorker = require('../workers/MainWorker-node').MainWorker;
    }

    /**
     * @override
     */
    initWorkerScript() {}

    /**
     * @override
     */
    addCustomWorkerScript(...args) {
        console.warn("addCustomWorkerScript is not supported in a Node environment.");
    }

    /**
     * @override
     */
    createWorker = function() {
        return new this.MainWorker();
    };
}

export let workerCreator = isNodeJS() ? new WorkerCreatorNode() : new WorkerCreator();

/** Legacy. Redirects to workerCreator.createWorker. */
export function createWorker(...args) {
    return workerCreator.createWorker(...args);
}

/** Legacy. Redirects to workerCreator.initWorkerScript. */
export function initWorkerScript(...args) {
    return workerCreator.initWorkerScript(...args);
}
