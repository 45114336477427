
const COMMON_MATERIAL_UNIFORMS_SIZE = 16; // in bytes

// Memory layout of CommonMaterialUniforms
// All offsets are defined in 32-bit Items (float/int)
const UniformOffsets = {
    edgeColor:         0, // ABGR int32 color
    doNotCutOverride:  1, // if true, cutplanes are ignored for whole scene (overriding the doNotCut flag of the material)
};

// Manages a small UniformBuffer with material properties that are just set once for each scene.
export class CommonMaterialUniforms {

    #device;

    // GPUBuffer + CPU buffer
    /** @type {GPUBuffer} */
    #commonMaterialUniforms;
    #commonMaterialUniformsCPU    = new Float32Array(COMMON_MATERIAL_UNIFORMS_SIZE / 4);
    #commonMaterialUniformsCPUInt = new Int32Array(this.#commonMaterialUniformsCPU.buffer);

    /**
     * @param {GPUDevice} device
     */
    constructor(device) {
        this.#device = device;
        this.#commonMaterialUniforms = this.#device.createBuffer({
            size: COMMON_MATERIAL_UNIFORMS_SIZE,
            usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
        });
    }

    /**
     * @param {number} edgeColorInt - Edge color as 32-bit integer.
     */
    setEdgeColorInt(edgeColorInt) {
        if (edgeColorInt !== this.#commonMaterialUniformsCPUInt[UniformOffsets.edgeColor]) {
            this.#commonMaterialUniformsCPUInt[UniformOffsets.edgeColor] = edgeColorInt;
            this.#device.queue.writeBuffer(this.#commonMaterialUniforms, 0, this.#commonMaterialUniformsCPU.buffer, 0,
                this.#commonMaterialUniformsCPU.byteLength);
        }
    }

    /**
     * @param {boolean} value - No effect if false. If true, cutplanes are ignored for whole scene (overriding the doNotCut flag of the material)
     */
    setDoNotCutOverride(value) {
        if (value !== !!this.#commonMaterialUniformsCPUInt[UniformOffsets.doNotCutOverride]) {
            this.#commonMaterialUniformsCPUInt[UniformOffsets.doNotCutOverride] = value;
            this.#device.queue.writeBuffer(this.#commonMaterialUniforms, 0, this.#commonMaterialUniformsCPU.buffer, 0,
                this.#commonMaterialUniformsCPU.byteLength);
        }
    }

    /**
     * @returns {GPUBuffer}
     */
    getGPUBuffer() {
        return this.#commonMaterialUniforms;
    }

    /**
     * @returns {number} byteSize on GPU
     */
    byteSize() {
        return this.#commonMaterialUniforms.size;
    }
}
