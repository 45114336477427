import { logger } from '../logger/Logger';
import { AnalyticsProviderInterface } from './interface';
import { getClientId } from '../getClientId';

export class ViewerAnalytics {
  // map of providerName : class, for e.g., 'mixpanel' : Mixpanel
  providerMap = {};

  instances = new Array();

  // Properties attached to all events
  // @property {String} [superProps.productId] - product_id, Environment or 'NOT_SET'
  // @property {String} [superProps.clientId] - client_id or 'NOT_SET'
  // @property {String} [superProps.lmvBuildType] - 'Production', 'Staging' etc.
  // @property {String} [superProps.lmvViewerVersion] - version number
  superProps = {};

  // True if analytics are supposed to be tracked at all
  shouldTrack = true;

  // to store track calls until the first instance is created
  trackCache = new Array();

  // Events that should get tracked only once per viewer session.
  oneTimers = {};

  // common information to be attached to Model.Loaded event
  #renderer;
  #vendor;

  /**
   * Register an analytics provider class
   * @param {object} PClass - Provider class of type AnalyticsProviderInterface
   */
  registerProvider(PClass) {
    if (!PClass) {
      logger.error('Undefined provider');
      return;
    }
    if (!PClass.name) {
      logger.error('missing provider name');
      return;
    }
    const n = PClass.name.toLowerCase();
    if (this.isProviderRegistered(n)) {
      logger.warn(`Provider with name ${PClass.name} already registered`);
    } else {
      this.providerMap[n] = PClass;
    }

    // create and initialize default instance
    const defaultInstance = this.createInstance(PClass.name, PClass.defaultOptions);
    this.instances.push(defaultInstance);
    if (this.shouldTrack) {
      this.init(defaultInstance);
    }

    // track data cached before the first instance was created
    if (this.trackCache.length > 0) {
      this.trackCache.forEach(({ event, properties }) => {
        this.track(event, properties);
      });
      this.trackCache = []; // clear
    }
  }

  /**
   * Returns if a ProviderClass with its name was already registered.
   * @param {object|string} PClassOrPClassName - ProviderClass object or its name
   * @returns True, if already registered.
   */
  isProviderRegistered(PClassOrPClassName) {
    const n = typeof PClassOrPClassName === 'string' ? PClassOrPClassName : PClassOrPClassName.name?.toLowerCase();
    return n in this.providerMap;
  }

  init(providerInstance) {
    if (!providerInstance.initialized) {
      providerInstance.init();
      providerInstance.register(this.superProps);
    }
  }

  createInstance(providerName, options) {
    const pname = providerName && providerName.toLowerCase();
    if (!(pname in this.providerMap)) {
      logger.error(`Unknown ${providerName}`);
      return;
    }

    const PClass = this.providerMap[pname];
    const instance = new PClass(options);
    if (!(instance instanceof AnalyticsProviderInterface)) {
      throw new Error('not an analytics provider');
    }

    // instance name
    PClass.instanceCount = PClass.instanceCount || 0;
    instance.name = `${pname}-${PClass.instanceCount}`; // for e.g., mixpanel-0
    PClass.instanceCount++;
    return instance;
  }

  optIn(options) {
    this.instances.forEach(i => this.init(i));
    this._callMethod('optIn', options);
    this.shouldTrack = true;
  }

  optOut(options) {
    this._callMethod('optOut', options);
    this.shouldTrack = false;
  }

  hasOptedOut() {
    return this._callMethod('hasOptedOut');
  }

  getDistinctId() {
    return this._callMethod('getDistinctId');
  }

  /**
   * Adds client information to analytics data if available given the access token,
   * as part of every tracked event.
   * @param {String} accessToken
   */
  async trackClientInfo(accessToken) {
    this.superProps.clientId = await getClientId(accessToken);
  }

  track(event, properties, isOneTimer) {
    if (!this.shouldTrack) {
      return;
    }

    if (!properties) {
      properties = {};
    }

    // In case this event is a one-timer, make sure to track it only once per viewer session.
    if (isOneTimer) {
        const eventWithProps = { event, properties };

        try {
            const key = JSON.stringify(eventWithProps);

            // Event was already tracked before - skip it.
            if (this.oneTimers[key]) {
                return;
            }

            this.oneTimers[key] = true;
        } catch (_) {
            // Unable to stringify event (probably because of a circular dependency - shouldn't happen anyway).
            // Don't crash because of it - just ignore isOneTimer flag for this one.
        }
    }

    if (this.instances.length === 0) {
      this.trackCache.push({ event, properties });
    } else {
      this._callMethod('track', event, properties);
    }
  }

  /**
   * Registers information about renderer, GPU etc. for Model Loaded event.
   * @param {String} renderer
   * @param {String} vendor
   */
  trackHWInfo(renderer, vendor) {
    this.#renderer = renderer ? renderer : 'NOT_SET';
    this.#vendor = vendor ? vendor : 'NOT_SET';
  }

  /**
   * Appends properties that should be provided by all 'viewer.model.load' events.
   * @param {Object} props - properties to append more information to
   */
  appendCommonPropsForModelLoad(props) {
    props.renderer = this.#renderer;
    props.vendor = this.#vendor;
  }

  identify(distinctId) {
    this._callMethod('identify', distinctId);
  }

  _callMethod(...args) {
    const methodName = args[0];
    const rest = args.slice(1, args.length);
    return this.instances.map(inst => ({
      name: inst.name,
      value: inst[methodName](...rest)
    }));
  }
}
