import { UploadTaskBase } from './UploadTaskBase';

/** @import { FragmentList } from "../../FragmentList" */

export class RemainingFragmentsTask extends UploadTaskBase {

    /**
     * Creates a new task to upload the remaining fragments of a mesh
     *
     * @param {OutOfCoreTileManager} outOfCoreTileManager - The OutOfCoreTileManager instance
     * @param {FragmentList} fragList - The fragment list
     * @param {number} bvhNodeId - The BVH node ID
     * @param {number[]|Int32Array} remainingFragmentIndices - The indices of the remaining fragments
     */
    constructor(outOfCoreTileManager, fragList, bvhNodeId, remainingFragmentIndices) {
        super(outOfCoreTileManager, fragList, bvhNodeId);
        this.remainingFragmentIndices = remainingFragmentIndices;
    }

    /**
     * Returns the geometries to be uploaded. Note that it happens by design that some geometries may appeear multiple
     * times. This is by design and necessary for refCount tracking.
     * @returns {THREE.BufferGeometry[]} The geometries to be uploaded
     */
    getGeometries() {
        // Note that we cannot use map() here, because it would turn geometries into "0" entries if indices are a typed array.
        const count = this.remainingFragmentIndices.length;
        const geoms = new Array(count);
        for (let i = 0; i < count; i++) {
            geoms[i] = this.fragList.getGeometry(this.remainingFragmentIndices[i]);
        }
        return geoms;
    }
}
