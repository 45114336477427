import { BufferGeometry } from "three";
import { BumpAllocator } from "./BumpAllocator";
import { Renderer } from "./Renderer";
import { USE_OUT_OF_CORE_TILE_MANAGER } from "../globals";

const MAX_COPIED_PER_FRAME = 10 * 1024 * 1024; // 10MB
export class VertexBuffer {

	/** @type {GPUBuffer|undefined} */
	#curVB;
	/** @type {GPUBuffer|undefined} */
	#curIB;
	/** @type {GPURenderPassEncoder|undefined} */
	#curPass;

	/** @type {GPUDevice} */
	#device;
	/** @type {BumpAllocator} */
	#uploadAllocator;
	/** @type {BumpAllocator} */
	#streamAllocator;
	/** @type {StagingBuffers} */
	#staging;
	/** @type {Set.<BufferGeometry>} */
	#streamDraws = new Set();
	#cleanups = 0;
	#bytesWritten = 0;
	#overWriteLimit = false;
	#dataFlushes = 0;
	#numWrites = 0;
	#numDraws = 0;

	/**
	 * @param {Renderer} renderer
	 */
	constructor(renderer) {
		const device = renderer.getDevice();
		this.#device = device;
		this.#uploadAllocator = new BumpAllocator(device, /*freesAreBatched=*/true);
		this.#streamAllocator = new BumpAllocator(device, /*freesAreBatched=*/true);
		this.#staging = new StagingBuffers(device);
	}

	static #disposeHandler(event) {
		const geometry = event.target;
		geometry.removeEventListener('dispose', VertexBuffer.#disposeHandler);
		VertexBuffer.deallocateGeometry(geometry);
	}

	/**
	 * Free up vertex buffer allocatations for this geometry, if there are any.
	 * @param {BufferGeometry} geometry
	 * @param {VertexBuffer|undefined} geometry.__gpu
	*/
	static deallocateGeometry(geometry) {
		// The event listener class overrides the this context when invoking this handler.
		// So we need to access the VertexBuffer instance via geometry.__gpu.
		if (geometry.__gpu) {
			geometry.__gpu.gFree(geometry);
		}
	}

	/**
	 * Flush all buffer copies from the staging buffers into the upload and stream
	 * allocator buffers.
	 * flushWrites() needs to be called before submitting any command buffers that
	 * referenced any of VertexBuffer's GPUBuffers.
	 */
	flushWrites() {
		if (this.#staging.flushData()) {
			this.#dataFlushes++;
		}
	}

	/**
	 * @param {GPUBuffer} dstBuffer
	 * @param {number} dstOffset
	 * @param {ArrayBuffer} srcBuffer
	 * @param {number} srcOffset
	 * @param {number} size
	 */
	#writeBuffer(dstBuffer, dstOffset, srcBuffer, srcOffset, size) {
		this.#bytesWritten += size;
		this.#numWrites++;
		if (this.#bytesWritten > MAX_COPIED_PER_FRAME) {
			this.#overWriteLimit = true;
		}

		// If the staging buffers are full, fallback to GPUQueue.writeBuffer().
		if (!this.#staging.writeData(dstBuffer, dstOffset, srcBuffer, srcOffset, size)) {
			this.#device.queue.writeBuffer(dstBuffer, dstOffset, srcBuffer, srcOffset, size);
		}
	}

	/**
	 *
	 * @param {BufferGeometry} geometry
	 * @param {BumpAllocator} allocator
	 */
	#initVB(geometry, allocator) {
		if (geometry.__gpu && !geometry.vbNeedsUpdate) {
			return;
		}

		// If we've initialized this VertexBuffer already we free up the GPU memory
		// we used previously.
		// @todo: We could instead detect if the previously used buffers are the same size
		// as what we want this time, and reuse them if so. This would save a small amount
		// processing time, and potentially reduce some fragmentation of the buffer.
		if (geometry.__gpu) {
			VertexBuffer.deallocateGeometry(geometry);
		}

		geometry.__gpu = allocator;

		let strideBytes = geometry.vbstride * 4;
		const vertAlloc = allocator.vAlloc(geometry.vb.byteLength, strideBytes);
		const vb = vertAlloc.bufferEntry;
		const baseVertex = vertAlloc.offset / strideBytes;
		this.#writeBuffer(
			vb.buffer, baseVertex * strideBytes, geometry.vb.buffer, geometry.vb.byteOffset, geometry.vb.byteLength);

		geometry.__gpuvb = vb;
		geometry.__gpuvbSize = geometry.vb.byteLength;
		geometry.__gpuvbBaseVertex = baseVertex;

		let len4 = geometry.ib.byteLength;
		if (len4 % 4) len4+=2;

		let lenLines = geometry.iblines?.byteLength || 0;
		if (lenLines % 4) lenLines += 2;

		const ibSize = len4 + lenLines;
		const indexAlloc = allocator.iAlloc(ibSize);
		const ib = indexAlloc.bufferEntry;
		const baseIndexOffset = indexAlloc.offset;

		if (geometry.ib.byteOffset + len4 > geometry.ib.buffer.byteLength) {
			// TODO: https://jira.autodesk.com/browse/VIZX-1666
			// This log is very noisy.
			// console.log("OUT OF BOUNDS FIX", ib.offset, len4);
			//This is an annoying case where the underlying array buffer doesn't have extra bytes to
			//compensate for the extra 2 bytes we add to the copy length to make it multiple of 4
			//Note that only triangle index buffers can be non-multiple of 4.
			let tmp = new Uint16Array(new ArrayBuffer(len4));
			tmp.set(geometry.ib);
			this.#writeBuffer(ib.buffer, baseIndexOffset, tmp.buffer, 0, len4);
		} else {
			this.#writeBuffer(ib.buffer, baseIndexOffset, geometry.ib.buffer, geometry.ib.byteOffset, len4);
		}

		if (geometry.iblines) {
			this.#writeBuffer(ib.buffer, baseIndexOffset + len4,
				geometry.iblines.buffer, geometry.iblines.byteOffset, lenLines);
		}

		geometry.__gpuib = ib;

		if (geometry.ib instanceof Uint16Array) {
			geometry.__gpuibType = "uint16";
			geometry.__gpuibShift = 1;
			geometry.iblines && (geometry.__gpuibLinesOffset = len4 / 2);
		} else if (geometry.ib instanceof Uint32Array) {
			geometry.__gpuibType = "uint32";
			geometry.__gpuibShift = 2;
			geometry.iblines && (geometry.__gpuibLinesOffset = len4 / 4);
		} else {
			console.warn("unknown index buffer type");
		}

		geometry.__gpuibBaseIndex = baseIndexOffset >> geometry.__gpuibShift;
		geometry.__gpuibSize = ibSize;

		geometry.vbNeedsUpdate = false;

		if (allocator === this.#uploadAllocator) {
			geometry.addEventListener('dispose', VertexBuffer.#disposeHandler);
		}

		// This is useful to debug memory leaks.
		// console.log(geometry.id, allocator.computeUsedBytes());
	}

	/**
	 * @param {BufferGeometry} geom
	*/
	initUploaded(geom) {
		if (this.#streamDraws.has(geom)) {
			VertexBuffer.deallocateGeometry(geom);
			this.#streamDraws.delete(geom);
		}

		this.#initVB(geom, this.#uploadAllocator);
	}

	/**
	 * @param {BufferGeometry} geom
	*/
	initStream(geom) {
		if (this.#streamDraws.has(geom)) {
			return;
		}

		this.#initVB(geom, this.#streamAllocator);
		this.#streamDraws.add(geom);
	}

	/**
	 * Free up all blocks in the stream allocator.
	 */
	#clearStreamBuffers() {
		for (const geom of this.#streamDraws) {
			if (geom.__gpu === this.#streamAllocator) {
				VertexBuffer.deallocateGeometry(geom);
			}
		}
		this.#streamDraws.clear();
		this.#streamAllocator.flushFreeBlocks();
	}

	/**
	 * Free up unneeded memory and per-frame state tracking.
	 * Should be called at the end of each frame.
	 */
	cleanup() {
		this.#uploadAllocator.flushFreeBlocks();
		this.#clearStreamBuffers();
		this.#staging.cleanup();

		this.#bytesWritten = 0;
		this.#overWriteLimit = false;
		this.#cleanups++;
		this.#dataFlushes = 0;
		this.#numWrites = 0;
		this.#numDraws = 0;
	}

	stats() {
		const totalMem = this.getTotalMemory() / 1024 / 1024 / 1024; // GB
		console.log(`Vertex Buffer Memory: ${totalMem} GB`);
		console.log(`Bytes written: ${this.#bytesWritten / 1024 / 1024} MB`);
		console.log(`Writes: ${this.#numWrites}`);
		console.log(`Flushed times: ${this.#dataFlushes}`);
		console.log(`Draws: ${this.#numDraws}`);
	}

	/**
	 * Get the total amount of memory being used, not counting the overhead of unused
	 * space in currently allocated buffers.
	 * @returns {number}
	 */
	getTotalMemory() {
		let total = 0;
		total += this.#uploadAllocator.computeUsedBytes().totalBytes;
		total += this.#streamAllocator.computeUsedBytes().totalBytes;
		total += this.#staging.totalAllocated();
		return total;
	}

	/**
	 * @param {Buffer} geometry
	 * @returns {boolean}
	 */
	canDraw(geometry) {
		if (!USE_OUT_OF_CORE_TILE_MANAGER) {
			return true;
		}

		const needsUpload = !geometry.__gpuvb || geometry.vbNeedsUpdate;
		if (!needsUpload) {
			return true;
		} else {
			const needed = geometry.vb.byteLength + geometry.ib.byteLength +
				(geometry.iblines?.byteLength || 0) + 4;
			return this.canUpload(needed);
		}
	}

	/**
	 * @param {number} size
	 * @returns {boolean} Whether or not size bytes can be uploaded this frame.
	 */
	canUpload(size) {
		// Allow one geometry per frame to be uploaded if it's bigger than the max
		// allowed copied bytes per frame.
		if (size > MAX_COPIED_PER_FRAME && !this.#overWriteLimit) {
			return true;
		}
		const leftThisFrame = MAX_COPIED_PER_FRAME - this.#bytesWritten;
		return leftThisFrame > size;
	}

	/**
	 * @param {GPURenderPassEncoder} passEncoder
	 * @param {BufferGeometry} geometry
	 */
	#prepareDraw(passEncoder, geometry) {
		if (USE_OUT_OF_CORE_TILE_MANAGER) {
			if (!geometry.__gpuvb || geometry.vbNeedsUpdate) {
				if (geometry.streamingDraw) {
					this.initStream(geometry);
				} else {
					// This needs fixing if it ever occurs.
					console.error('Non-streaming-draw VB should have already been uploaded!');
					return;
				}
			}
		} else if (!geometry.__gpuvb || geometry.vbNeedsUpdate) {
			// All geometry is uploaded at draw time when not using OutOfCoreTileManager.
			this.initUploaded(geometry);
		}

		const vb = geometry.__gpuvb;
		const ib = geometry.__gpuib;
		if (!vb || !ib) {
			console.error('Still missing VB or IB when drawing!');
			return;
		}

		this.#numDraws++;

		if (this.#curPass !== passEncoder) {
			passEncoder.setVertexBuffer(0, vb.buffer);
			this.#curVB = vb.buffer;

			passEncoder.setIndexBuffer(ib.buffer, geometry.__gpuibType);
			this.#curIB = ib.buffer;

			this.#curPass = passEncoder;
		} else {
			if (vb.buffer !== this.#curVB) {
				passEncoder.setVertexBuffer(0, vb.buffer);
				this.#curVB = vb.buffer;
			}

			if (ib.buffer !== this.#curIB) {
				passEncoder.setIndexBuffer(ib.buffer, geometry.__gpuibType);
				this.#curIB = ib.buffer;
			}
		}
	}

	/**
	 * @param {GPURenderPassEncoder} passEncoder
	 * @param {BufferGeometry} geometry
	 * @param {number} instanceId
	 */
	draw(passEncoder, geometry, instanceId) {
		this.#prepareDraw(passEncoder, geometry);

		const instanceCount = geometry.numInstances ?? 1;
		passEncoder.drawIndexed(geometry.ib.length, instanceCount, geometry.__gpuibBaseIndex, geometry.__gpuvbBaseVertex, instanceId);
	}

	/**
	 * @param {GPURenderPassEncoder} passEncoder
	 * @param {BufferGeometry} geometry
	 * @param {number} instanceId
	 */
	drawEdges(passEncoder, geometry, instanceId) {
		if (!geometry.iblines) {
			return;
		}

		this.#prepareDraw(passEncoder, geometry);

		const instanceCount = geometry.numInstances ?? 1;
		let firstIndex = geometry.__gpuibBaseIndex + geometry.__gpuibLinesOffset;
		passEncoder.drawIndexed(geometry.iblines.length, instanceCount, firstIndex, geometry.__gpuvbBaseVertex, instanceId);
	}

}

const MAPPED_BUF_SIZE = 1 * 1024 * 1024; // 1MB
const MAX_BUFS_PER_FRAME = Math.ceil(MAX_COPIED_PER_FRAME / MAPPED_BUF_SIZE);

class StagingBuffers {
	/** @type {GPUDevice} */
	#device

	/**
	 * @typedef {Object} MappedBuffer
	 * @property {GPUBuffer} buffer
	 * @property {ArrayBuffer|null} range
	 * @property {number} offset
	 * @property {number} size
	 */

	/** @type {MappedBuffer[]} */
	#mappedBuffers = [];
	/** @type {GPUCommandEncoder} */
	#copyEncoder;
	/** @type {MappedBuffer[]} */
	#usedThisFrame = [];

	/**
	 * @param {GPUDevice} device
	 */
	constructor(device) {
		this.#device = device;
	}

	/**
	 * @returns {MappedBuffer}
	 */
	#getMappedBuffer() {
		if (this.#mappedBuffers.length) {
			return this.#mappedBuffers.shift();
		}

		const buffer = this.#device.createBuffer({
			size: MAPPED_BUF_SIZE,
			usage: GPUBufferUsage.MAP_WRITE | GPUBufferUsage.COPY_SRC,
			mappedAtCreation: true,
		});
		return {
			buffer,
			range: null,
			offset: 0,
			size: MAPPED_BUF_SIZE,
		};
	}

	/**
	 * @param {number} size
	 * @returns {MappedBuffer|null}
	 */
	#getBufferWithSpace(size) {
		for (const mapped of this.#usedThisFrame) {
			if (mapped.buffer.mapState != 'mapped') {
				continue;
			}
			if ((mapped.size - mapped.offset) > size) {
				return mapped;
			}
		}

		if (this.#usedThisFrame.length >= MAX_BUFS_PER_FRAME) {
			return null;
		}

		const newThisFrame = this.#getMappedBuffer();
		this.#usedThisFrame.push(newThisFrame);
		return newThisFrame;
	}

	totalAllocated() {
		let total = 0;
		for (const mapped of this.#mappedBuffers) {
			total += mapped.size;
		}
		for (const mapped of this.#usedThisFrame) {
			total += mapped.size;
		}
		return total;
	}

	/**
	 * @param {GPUBuffer} dstBuffer
	 * @param {number} dstOffset
	 * @param {ArrayBuffer} srcBuffer
	 * @param {number} srcOffset
	 * @param {number} size
	 * @returns {boolean} Whether or not the write was successful.
	 *   Writes could fail if there is not suffient space in any staging buffers
	 *   left this frame, or if size is bigger than the staging buffer size.
	 */
	writeData(dstBuffer, dstOffset, srcBuffer, srcOffset, size) {
		if (size > MAPPED_BUF_SIZE) {
			return false;
		}

		const mapped = this.#getBufferWithSpace(size);
		if (!mapped) {
			return false;
		}

		if (!mapped.range) {
			mapped.range = mapped.buffer.getMappedRange();
		}

		const mappedArray = new Uint8Array(mapped.range, mapped.offset, size);
		mappedArray.set(new Uint8Array(srcBuffer, srcOffset, size));

		if (!this.#copyEncoder) {
			this.#copyEncoder = this.#device.createCommandEncoder();
		}
		this.#copyEncoder.copyBufferToBuffer(
			mapped.buffer, mapped.offset, dstBuffer, dstOffset, size);

		mapped.offset += size;

		return true;
	}

	/**
	 * Submit copies that have been written to staging buffers since
	 * the last flushData() call.
	 * @returns {boolean} Whether or not any copies were flushed.
	 */
	flushData() {
		if (!this.#copyEncoder) {
			return false;
		}

		for (const mapped of this.#usedThisFrame) {
			if (mapped.buffer.mapState == 'mapped') {
				mapped.buffer.unmap();
				mapped.range = null;
			}
		}

		this.#device.queue.submit([this.#copyEncoder.finish()]);
		this.#copyEncoder = null;

		return true;
	}

	cleanup() {
		// In cases where geometry was uploaded this frame but not yet drawn,
		// there may be some copies to flush.
		this.flushData();

		for (const unmapped of this.#usedThisFrame) {
			if (unmapped.buffer.mapState == 'unmapped') {
				unmapped.buffer.mapAsync(GPUMapMode.WRITE)
					.then(() => {
						this.#mappedBuffers.push({
							...unmapped,
							offset: 0,
						});
					})
			}
		}

		this.#usedThisFrame = [];
	}
}
