import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {UniformBuffer} from "../UniformBuffer";
import lineUniforms from "./line_uniforms.wgsl";
import {
	VBB_MITER_SEGMENT_LIMIT,
	VBB_MITER_SEGMENT_SCALE_FACTOR,
	VBB_MITER_SEGMENT_CP,
} from '../../../file-loaders/lmvtk/common/VertexBufferBuilder';

export function getLineUniformsDeclaration(group) {
	return $wgsl(lineUniforms, { group });
}

export class LineUniforms extends UniformBuffer {

	#device;
	#layout;
	#bindGroup;
	#pixelsPerUnit;
	#lineStyleBuffer;

	constructor(device) {
		super(device, 16, true, true);

		this.setFloat(5, 0.0); // swap
		this.setFloat(7, 1.0); //initialize opacity

		this.#device = device;

		this.#layout = device.createBindGroupLayout({
			entries: [
				{
					binding: 0,
					visibility: GPUShaderStage.FRAGMENT | GPUShaderStage.VERTEX,
					buffer: {}
				},
				{
					binding: 1,
					visibility: GPUShaderStage.FRAGMENT,
					buffer: {
						type: 'read-only-storage'
					}
				}
			]
		});

		// We're just allocating this as a placeholder until we get the actual line style buffer.
		this.#lineStyleBuffer = this.#device.createBuffer({
			size: 64,
			usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
		});

		this.setInt(8, 0); // initialize bytes-per-pattern (lineStyleWidth)

		// Do these values ever need to change?
		this.setMiterData(VBB_MITER_SEGMENT_LIMIT, VBB_MITER_SEGMENT_SCALE_FACTOR, VBB_MITER_SEGMENT_CP);

		this.#createBindGroup();
	}

	#createBindGroup() {
		this.#bindGroup = this.#device.createBindGroup({
			layout: this.#layout,
			entries: [
				{
					binding: 0,
					resource: {
						buffer: this.getBuffer(),
					}
				},
				{
					binding: 1,
					resource: {
						buffer: this.#lineStyleBuffer,
					}
				}
			]
		});
	}

	updatePixelScale(pixelsPerUnit, camera) {

		this.#pixelsPerUnit = pixelsPerUnit;

		//TODO: the uniforms may need to be set per each render batch, because
		//in general they can be model specific (with per model scale value)

		//if (m.isScreenSpace) {
			this.setFloat(1, 0.5);
		//} else {
		//	this.setFloat(1, 0.5 / pixelsPerUnit);
		//}

		//This setting applies to screen space 2D shader
		if (camera?.isPerspective) {
			// Pass parameters to calculate pixelPerUnit for each vertex
			const fovInRad = camera.fov * Math.PI / 180.0;
			this.setFloat(6,  Math.tan(fovInRad * 0.5));
		} else {
			this.setFloat(6, 0); // A value of 0 signals to use the global pixelsPerUnit from uniform
		}

		this.setFloat(0, pixelsPerUnit);
	}

	setLineStyleBuffer(buffer, width) {
		if (this.#lineStyleBuffer && this.#lineStyleBuffer.size !== buffer.byteLength) {
			this.#lineStyleBuffer.destroy();
			this.#lineStyleBuffer = null;
		}

		if (!this.#lineStyleBuffer) {
			this.#lineStyleBuffer = this.#device.createBuffer({
				size: buffer.byteLength,
				usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
			});
		}

		this.#device.queue.writeBuffer(
			this.#lineStyleBuffer,
			0,
			buffer.buffer,
			0,
			buffer.byteLength
		);

		this.setInt(8, width);
	}

	setTargetSize(w, h) {
		this.setFloat(2, w);
		this.setFloat(3, h);
	}

	/**
	 * @param {number} miterLimit
	 * @param {number} miterScaleFactor
	 * @param {number} miterCp
	 */
	setMiterData(miterLimit, miterScaleFactor, miterCp) {
		this.setFloat(9, miterLimit);
		this.setFloat(10, miterScaleFactor);
		this.setFloat(11, miterCp);
	}

	getLayout() {
		return this.#layout;
	}

	getBindGroup() {
		return this.#bindGroup;
	}

}
