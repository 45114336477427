import { wgsl } from 'wgsl-preprocessor/wgsl-preprocessor';
/**
 * Checks if there is "enough" `values` for the given `tokens`.
 * Expects `tokens` to be an existing array.
 */
function check(tokens, values) {
	if (!values || values.length < tokens.length) {
		const noValues = tokens.slice(values?.length || 0).map(t => `"$${t}"`).join(', ');
		throw new Error(`[wgpu] Missing values for: ${noValues}`);
	}
	return values;
}

/**
 * Reifies `$...` tokens in wgsl shaders. `...` should follow the `\w+` pattern to be discoverable.
 *
 * Supported cases depending on a type of `values`:
 * - if `values` is a primitive, rest arguments after `code` will be used as token values;
 * - if `values` is an object, it's used as a simple single-level dictionary, e.g.,
 *   for the object `{ foo: 'bar' }`, `bar` replaces the token `$foo`;
 * - if `values` is an array, tokens are replaced with its elements by order of their occurence.
 *
 * In each of the non-primitive cases, rest `...args` is ignored.
*/
export function $wgsl(code, values, ...args) {
	const $ = /\$\w+/;
	const tokens = code.match(/(?<=\$)(\w+)/g);
	if (!tokens || !tokens.length) return code;

	if (Array.isArray(values)) {
		return wgsl(code.split($), ...check(tokens, values));
	}
	if (values && !Array.isArray(values) && typeof values === 'object') {
		return wgsl(code.split($), ...tokens.map(token => {
			if (!Object.hasOwn(values, token)) {
				throw new Error(`[wgpu] Missing value for "$${token}"`);
			}
			const value = values[token];
			if ((typeof value === 'object' && value !== null) || typeof value === 'function') {
				throw new Error(`[wgpu] Unsupported value type "${typeof value}"`);
			}
			return value;
		}));
	}
	return wgsl(code.split($), ...check(tokens, [values, ...args]));
}

export { wgsl };
