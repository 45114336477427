
import { OutOfCoreTaskBase } from './OutOfCoreTaskBase.js';
import { OBJECT_STRIDE } from '../../../wgpu/main/uniforms/ObjectUniforms.js';
/**
 * For WebGPU: Purpose is to upload the uniforms of a RenderBatch to the GPU.
 * Once done, it also allows renderBundles for the RenderBatch.
 */
export class UniformUploadTask extends OutOfCoreTaskBase {

    /** @type {RenderBatch} */
    #bvhRenderBatch;

    // Currently consumed GPU memory. Only >0 if execute() was called without subsequent freeMemory().
    #consumedBytes = 0;

    /**
     * @param {OutOfCoreTileManager} outOfCoreTileManager The OutOfCoreTileManager instance creating this task
     * @param {RenderBatch}          bvhRenderBatch       Note that we finally use the ConsolidatedRenderBatch, but this may not be available at construction time yet
     */
    constructor(outOfCoreTileManager, renderBatch) {
        super(outOfCoreTileManager);
        this.#bvhRenderBatch = renderBatch;
    }

    /**
     * @override
     * Upload RenderBatch uniforms to GPU.
     * Note: Consolidation must be available before executing this task. Otherwwise, getting the ConsolidatedRenderBatch will fail.
     * @returns {number|undefined} The memory consumed when executing the task, undefined if no consolidation is available
     */
    execute() {
        const batch = this.#bvhRenderBatch;
        const renderer = this.outOfCoreTileManager.getRenderer();

        // Before uploading, make sure that the batch sorted for efficient rendering.
        batch.sortByVertexBuffer(batch.start, batch.lastItem);

        renderer.uploadUniforms(batch);

        // Make sure the update doesn't happen again unless actual changes occur afterwards.
        // Note that some modification callbacks on FragmentList will cause setting uniformsNeedUpdate during loading.
        // So, without resetting it here, they would be uploaded twice.
        batch.uniformsNeedUpdate = false;

        this.#consumedBytes = this.getMemoryCost();

        // Block interruption of pre-optimized RenderBatches. Why this?
        //  - For optimized RenderBatches, we want to use RenderBundles to avoid the CPU effort for forEachWGPU.
        //  - RenderBundles require that all uniforms and geometry is constantly available on GPU. For optimized tiles, we can guarantee this.
        //  - This is only possible if we let the RenderBatch finish the RenderBundle recording.
        if(!batch.sortObjects) {
            // Note that just allowInterruption = false is not sufficient,
            // because useRenderBundles would keep false if renderBatch is already complete
            batch.allowInterruption = false;
            batch.useRenderBundles = true;
        }

        // Indicate that everything is ready for this RenderBatch, i.e.
        //  - fragments are all loaded (otherwise, the BvhNode for this Batch wouldn't have been initialized in the first place)
        //  - all fragment geometry is uploaded to GPU (this is true because the UploadTask always runs at the end of the UploadTasks for fragment geometry)
        batch.isComplete = true;

        return this.#consumedBytes;
    }

    /**
     * @override
     * Deletes RenderBatch uniforms from GPU
     * @returns {number} The memory that could be freed
     */
    freeMemory() {
        const freedMemory = this.#consumedBytes;
        const batch = this.#bvhRenderBatch;
        const renderer = this.outOfCoreTileManager.getRenderer();
        renderer.freeUniforms(batch);
        this.#consumedBytes = 0;

        // Rendering this batch will get slower again and we cannot use the render bundles anymore.
        // Why? The RenderBundles rely on the fact that uniforms and geometry are all readily available on GPU.
        // This is only true for fully optimized tiles.
        // Note that we rely on the fact here that this task comes last in the chain (i.e. after geometry upload task)
        if(!batch.sortObjects) {
            batch.allowInterruption = true;
            batch.clearRenderBundles();

            // Don't use renderBundles again until the task is executed again.
            batch.useRenderBundles = false;
        }

        // Strictly speaking, the batch is still complete at this point. But we are
        // just starting to free the node, so it will soon switch to incomplete.
        batch.isComplete = false;

        return freedMemory;
    }

    /**
     * @override
     * Returns the memory cost of the task
     * @returns The memory cost of the task
     */
    getMemoryCost() {
        const uniformCount = this.#bvhRenderBatch.count;

        // For performance reasons, WebGPURenderer does not create own buffers per RenderBatch.
        // Instead, it uses pages of fixed size. Therefore, the actually allocated GPU memory is higher
        // than the theoretical memory consumption, becasue...
        //  - There can be fragmentation in the buffer pages
        //  - A buffer page is always fully allocated, even if only a small part of it is used
        // However, these aspects vary depending on other tiles, so we cannot use them for a consistent computation of task costs.
        // Therefore, as a trade-off, we add 10% in general to account for unused GPU space on average.
        const OverheadFactor = 1.1;

        const bytes = Math.round(uniformCount * OBJECT_STRIDE * OverheadFactor);
        return bytes;
    }

    /**
     * @override
     * Returns the memory that can be freed by this task
     * @returns {number} The memory that can be freed by this task
     */
    getFreeableMemory() {
        return this.#consumedBytes;
    }
}
