export function InitializeStateCachingIntrusion (gl) {

    // COLOR MASK CACHING

    let _colorMask = gl.colorMask.bind(gl);
    let _colorMaskValues = gl.getParameter(gl.COLOR_WRITEMASK);

    // Override the original color mask function to cache the values and avoid unnecessary WebGL calls.
    gl.colorMask = function(r, g, b, a) {
        if (!(r === _colorMaskValues[0] &&
            g === _colorMaskValues[1] &&
            b === _colorMaskValues[2] &&
            a === _colorMaskValues[3])) {
            _colorMaskValues[0] = r;
            _colorMaskValues[1] = g;
            _colorMaskValues[2] = b;
            _colorMaskValues[3] = a;

            _colorMask(r, g, b, a);
        }
    };

    // Expose the currently cached color mask values.
    gl.getColorMask = function() {
        // Return a copy of the array to avoid modifying the cached values.
        return _colorMaskValues.slice();
    };
}

