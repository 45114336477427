import { createLeechViewer } from './LeechViewer';
import { MaterialManager } from "../wgs/render/MaterialManager";

// MultiViewerFactory - encapsulates the creation leech viewer.
export class MultiViewerFactory {
    constructor() {

        // Map of all the models that are already loaded some viewer.
        // Each item contains the model instance, and a counter of how many viewer's are using it.
        this.sharedResources = {
            loadedModels: {},
        };

        // WebGL also shares a glrenderer and materialManager between all viewers.
        if(!Autodesk.Viewing.Private.IsUsingWebGPU()){
            const glrenderer = Autodesk.Viewing.Private.createRenderer(undefined, { alpha: true });
            const materialManager = new MaterialManager(glrenderer);

            this.sharedResources = {
                ...this.sharedResources,
                glrenderer,
                materialManager
            };
        }
    }

    createViewer(container, config, ViewerClass = Autodesk.Viewing.Viewer3D) {
        return createLeechViewer(container, config, this.sharedResources, ViewerClass);
    }

    destroy() {
        this.sharedResources = null;
    }
}
