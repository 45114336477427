import { logger } from "../../logger/Logger";
import { RenderContextBase } from "../render/RenderContextBase";
import { errorCodeString, ErrorCodes } from "../../file-loaders/net/ErrorCodes";
import { isMobileDevice, isNodeJS } from "../../compat";
import * as THREE from "three";
import { getGlobal } from "../../compat";

/** @import { Renderer } from './Renderer' */

export function cubicBezier(p, t) {
	//var cx = 3.0 * p[0];
	//var bx = 3.0 * (p[2] - p[0]) - cx;
	//var ax = 1.0 - cx -bx;
	var cy = 3.0 * p[1];
	var by = 3.0 * (p[3] - p[1]) - cy;
	var ay = 1.0 - cy - by;

	//return ((ax * t + bx) * t + cx) * t;
	return ((ay * t + by) * t + cy) * t;
}

export function RenderContextWebGPU() {
	/** @type {Renderer} */
	var _renderer;

	//The camera and lights used for an entire progressive pass (potentially several GL frames)
	var _camera;
	var _lights;

	var _lastIdAtPixelsResults = {};

	var _exposure = 0.0;
	var _exposureBias = 0.0;
	var _envRotation = 0.0;
	var _tonemapMethod = 0;
	var _unitScale = 1.0;

	var _w, _h;
	var _warnedLeak = false;

	// An offscreen context avoids affecting the main canvas Rendering
	var _isOffscreen = false;

	var _idReadbackBuffers = {};
	var _modelIdReadbackBuffers = {};
	var _idRes = [0, 0]; // Reused in rolloverObjectViewport

	var _clearAlpha = 1.0;
	var _useOverlayAlpha = 1.0;
	var _isWeakDevice = false;

	// Smooth fade-in of roll-over highlighting
	var _lastObjTime = 0,
		_lastHighlightId = 0,
		_lastHighlightModelId = 0,
		_lastObjChanged = false,
		_lastGlowFlag = 0,
		_easeCurve = [0.42,0,1,1],
		_easeSpeed = 0.004,
		_rollOverFadeEnabled = true;

	//Rendering options
	var _settings = {
		antialias: true,
		sao: false,
		useHdrTarget: false,
		haveTwoSided: false,
		customPresentPass: false,
		envMapBg: false,
		numIdTargets: 3, //must be 1 or 3; 3 is required for multi-model rollover highlight to work properly.
		renderEdges: false,
		copyDepth: false, // whether to use depth buffer copying instead of sharing
	};

	var _oldSettings = {};

	// If a target is set (default null), the final frame is rendered into _offscreenTarget instead of the canvas.
	var _offscreenTarget = null;

	var _isInitialized = false;

	var _userFinalPass = null;

	// see waitForIdReadback
	var _waitForIdReadbackPromise = null; // null|Promise

	//TODO: hide this once there is a way
	//to obtain the current pipeline configuration
	this.settings = _settings;

	this.isWeakDevice = function() { return _isWeakDevice; };


	// @param {Renderer}             webGpuRenderer
	// @param {number}               width, height - render target extents
	// @param {object}               [options]
	// @param {bool}                 [options.offscreen] - By default (false), we render into the canvas of WebGLRenderer. If true, we render into an offscreen target instead - without affecting the main canvas.
	//                                                                Note: This flag is only relevant for 3D. For 2D, we always use idBufferSelection if we have an idBuffer.
	this.init = function (webGPURenderer, width, height, options = {}) {

		webGPURenderer.initSync(width, height);

		_isWeakDevice = isMobileDevice();

		_w = width;
		_h = height;

		_renderer = webGPURenderer;

		_isOffscreen = !!options.offscreen;

		//delayed until first begin frame
		//this.initPostPipeline(_settings.sao, _settings.antialias);
	};


	this.setDepthMaterialOffset = function(on,factor,units) {
	};

	this.setUserFinalPass = function(pass) {
		_userFinalPass = pass;
	};

	// Fades the overlay update in over time.
	// For rollover highlighting, which increases in effect as you wait.
	this.overlayUpdate = function() {

		if (_lastObjChanged) {
			_lastObjChanged = false;
			return true;
		}

		let noGlow = _lastGlowFlag === 0;
		let noRollover = _lastHighlightId === 0 || _lastHighlightId === -1;

		if (noGlow && noRollover)
			return false;

		var old = _renderer.getBlendSettings().getHighlightIntensity();

		var current = 1.0;
		if (_rollOverFadeEnabled) {
			// Multiply number of milliseconds that has elapsed by the
			// speed, 1/milliseconds, the time the transition should take.
			// So if _easeSpeed is, say, 1/1000, the transition takes a second;
			// 2/1000 is half a second, etc.
			var t = ((performance.now() - _lastObjTime) * _easeSpeed);
			t = Math.min(t, 1.0);

			// not a linear transition; use a cubic Bezier curve to ease in and out
			current = cubicBezier(_easeCurve, t);
		}

		// if intensity value has changed, update the shader's uniform
		if (old !== current) {
			_renderer.getBlendSettings().setHighlightIntensity(current);
			return true;
		}

		return false;
	};

	// Enable/Disable smooth fading of roll-over highlight intensity.
	this.setRollOverFadeEnabled = function(enabled) {
		_rollOverFadeEnabled = enabled;
	};

	// clear the color target and other targets, as needed
	this.beginScene = function (prototypeScene, camera, customLights, needClear) {
		_camera = camera;
		_lights = customLights;
		_lastIdAtPixelsResults = {};

		if (!_isInitialized && _w) {
			this.initPostPipeline(_settings.sao, _settings.antialias);
			_isInitialized = true;
		} else if (!_w) {
			if (!_warnedLeak && !isNodeJS()) {
				logger.error("Rendering to a canvas that was resized to zero. If you see this message you may be accidentally leaking a viewer instance.", errorCodeString(ErrorCodes.VIEWER_INTERNAL_ERROR));
				_warnedLeak = true;
			}
			return;
		}

		//We need to render once with the "prototype" scene which
		//only contains the cameras and lights, so that their positions
		//and transforms get updated to the latest camera. Hence the
		//call to render instead of just clear.


		//Clear the color target
		if (needClear) {

			_renderer.getEnvMapPass().setCamera(_camera);

			_renderer.renderBackground(_settings.envMapBg);

			_renderer.clearMainTargets();

			//Done when rendering overlays
			//_renderer.clearOverlayTargets();
		}

		if (!_settings.sao) {
			// Ensure that any previous SSAO computation post-process target is not blended in.
			// This looks redundant with computeSSAO()'s code setting this blend off. However, it's
			// possible for computeSSAO() to not be executed if (a) smooth navigation and AO are both on
			// and (b) the scene is moving. In that case, smooth navigation turns off AO entirely in
			// Viewer3DImpl.js and computSSAO() is never called at all.
			_renderer.getBlendSettings().setAOEnabled(false);
		}

		// Render the prototype/pre-model scene, which may also contain some user added custom geometry.
		// The key bit here is the "updateLights" true flag, which updates the lights for the scene; this is the
		// only place this flag is passed in as true.
		_renderer.beginScene(_camera, _lights);
		_renderer.renderScenePart(prototypeScene, _settings.renderEdges);
	};

	// Called incrementally by the scene traversal, potentially
	// across several frames.
	this.renderScenePart = function (scene, want_colorTarget, want_saoTarget, want_idTarget) {

		_lastIdAtPixelsResults = {};

		_renderer.renderScenePart(scene, _settings.renderEdges);
	};


	this.clearAllOverlays = function () {
		_renderer.clearOverlayTargets();
	};

	this.renderOverlays = function(overlays, lights, disableClear) {
		let haveOverlays = false;

		//NOTE: Disable clear was ported from WebGL for the SplitScreen extension which clears "manually" and calls
		//      renderOverlays per viewport. Maybe it can be removed again if a viewport-aware clear is implemented.
		if (!disableClear) {
			//TODO: only needs to be done when targets are not already clear (i.e. some overlay was previously rendered into them)
			_renderer.clearOverlayTargets();
		}

		for (let key in overlays) {
			let p = overlays[key];
			let s = p.scene;
			let c = p.camera ? p.camera : _camera;

			if (s.children.length) {

				if (!haveOverlays) {
					haveOverlays = true;
				}

				_renderer.renderOverlay(s, c, p.materialPre, p.materialPost, _settings.renderEdges, p.edgeColor, lights);
			}
		}

		_renderer.getBlendSettings().setUseOverlay(haveOverlays);
	};

	// Takes color buffer, uses normal and depth buffer, puts SSAO shading into _ssaoTarget.
	// _postTarget1 is used along the way to ping-pong and do a separable blur on the results.
	this.computeSSAO = function(skipAOPass) {
		if (!skipAOPass && _settings.sao) {
			_renderer.getSAO().run(_camera);
			_renderer.getBlendSettings().setAOEnabled(true);
			//console.timeEnd("SAOblur");
		} else {
			// Ensure that any previous SSAO computation post-process target is not blended in.
			_renderer.getBlendSettings().setAOEnabled(false);
		}
	};

	// Returns the final render target that presentBuffer eventually render to.
	this.getFinalTarget = function() {
		return _offscreenTarget || null;
	};

	// userFinalPass is used by stereo rendering, giving the context to use for where the render should be put.
	// If no context is given, the default frame buffer is used.
	this.presentBuffer = function (userFinalPass, waitForDone) {
		// userFinalPass may either be set permanently or passed by a parameter by an overloaded RenderContext.
		_renderer.present(_settings.antialias, _camera, waitForDone, userFinalPass || _userFinalPass);
	};

	this.composeFinalFrame = function (skipAOPass, skipPresent, waitForDone) {
		//Apply the post pipeline and then show to screen.
		//Note that we must preserve the original color buffer
		//so that we can update it progressively

		if (!_renderer)
			return;

		// always called, so that useAO is set to 0 if not in use.
		this.computeSSAO(skipAOPass);

		if (!skipPresent)
			this.presentBuffer(undefined, waitForDone);

		//console.timeEnd("post");

	};

	this.cleanup = function () {

		if (_renderer) {
			_renderer.cleanup();
		}

		_lastIdAtPixelsResults = {};
		_idReadbackBuffers = {};
		_modelIdReadbackBuffers = {};
	};

	this.setSize = function (w, h, force, suppress) {

		_w = w;
		_h = h;

		_settings.logicalWidth = w;
		_settings.logicalHeight = h;

		//Just a way to release the targets in cases when
		//we use a custom render context and don't need this one
		//temporarily
		if ((w === 0 && h === 0) || !_renderer) {
			this.cleanup();
			return;
		}

		var sw = 0 | (w * _renderer.getPixelRatio());
		var sh = 0 | (h * _renderer.getPixelRatio());

		_settings.deviceWidth = sw;
		_settings.deviceHeight = sh;

		// normally, render() calls setRenderTarget, which properly sets the size to be
		// the correct viewport for rendering. However, setAOEnabled also calls this
		// method, to allocate or deallocate the various SSAO buffers, etc. Because
		// post processing can increase the size of the target by 2x (code below),
		// we do not want to have setAOEnabled touch the renderer's setSize. Long and
		// short, setAOEnabled sends in "suppress" as true. LMV-2863
		if (!suppress) {
			if (_isOffscreen) {
				// only set Viewport (which can be recovered later), but do not affect WebGLCanvas
				_renderer.setViewport(0, 0, w, h);
			} else {
				_renderer.setSize(w, h);
			}
		}

		//logger.log("width: " + sw + " height: " + sh);
	};



	this.getMaxAnisotropy = function () {
		return _renderer ? _renderer.getMaxAnisotropy() : 0;
	};

	// HACK: returns MRT flags required by this render context
	// so that the flags can be passed to the material manager
	this.mrtFlags = function() {
		return {
			mrtNormals: true,
			mrtIdBuffer: true
		};
	};

	this.getAntialiasing = function () {
	   return _settings.antialias;
	};

	this.initPostPipeline = function (useSAO, useFXAA) {

		//TODO: Do we want to move the IE check to higher level code?
		_settings.sao = useSAO;
		_settings.antialias = useFXAA;

		//Also reallocate the render targets
		this.setSize(_w, _h);
	};

	this.setClearColors = function (colorTop, colorBot) {
		if (!colorBot) {
			colorBot = colorTop;
		}

		_renderer.getGradientPass().setClearColors(colorTop.x, colorTop.y, colorTop.z, colorBot.x, colorBot.y, colorBot.z);
	};

	/**
	 * Turn on or off the use of the overlay alpha when computing the diffuse color's alpha
	 * @param {Boolean} value - true to enable, false to disable.
	 */
	this.useOverlayAlpha = function(value) {
		_useOverlayAlpha = value;
	};

	this.setClearAlpha = function(alpha) {
		_clearAlpha = alpha;
		_renderer.getGradientPass().setAlpha(alpha);
		_renderer.getEnvMapPass().setAlpha(alpha);
	};

	this.getClearAlpha = function() {
		return _clearAlpha;
	};

	this.setAOEnabled = function(enabled) {
		_settings.sao = enabled;
		_oldSettings.sao = _settings.sao;
		_renderer.getBlendSettings().setAOEnabled(enabled);
		// recreate required buffers when sao is turned on; do not reset rendering size
		this.setSize(_w, _h, false, true);
	};

	/**
	 * @param {Number|undefined} radius - SAO radius in meters
	 * @param {Number|undefined} intensity - SAO intensity (default 1.0)
	 * @param {Number|undefined} blendBias - Fixed bias added to ambient occlusion factor (effectively increases brightness)
	 */
	this.setAOOptions = function (radius, intensity, blendBias) {

//console.log("SAO options", radius / _unitScale, intensity, blendBias);

		let bias;

		if (radius !== undefined) {
			let unitsPerMeter = this.getUnitScale();
			radius /= unitsPerMeter;
			bias = 0.01 / unitsPerMeter; //TODO: should probably not be hardcoded here, but deeper down
		}

		_renderer.getSAO().setAOOptions(radius, bias, intensity);

		if (blendBias !== undefined) {
			_renderer.getBlendSettings().setAOBias(blendBias);
		}

	};

	this.getAOEnabled = function() {
		return _settings.sao;
	};

	/**
	 * @returns {number}
	 */
	this.getAORadius = function () {
		return _renderer.getSAO().getAOOptions().radius;
	};

	/**
	 * @returns {number}
	 */
	this.getAOIntensity = function() {
		return _renderer.getSAO().getAOOptions().intensity;
	};

	/**
	 * @returns {number}
	 */
	this.getAOBias = function() {
		return _renderer.getSAO().getAOOptions().bias;
	};

	this.setCubeMap = function(map) {
		_renderer.getEnvMapPass().setCubeMap(map);

		if (!map)
			this.toggleEnvMapBackground(false);
	};

	this.setEnvRotation = function(rotation) {
		_envRotation = rotation;
		_renderer.getEnvMapPass().setEnvRotation(rotation);
	};

	this.getEnvRotation = function() {
		return _envRotation;
	};

	this.setEnvExposure = function (exposure) {
		_renderer.getEnvMapPass().setEnvExposure(exposure);
		_exposure = exposure;
	};

	this.setTonemapExposureBias = function (bias) {
		_exposureBias = bias;

		_renderer.getEnvMapPass().setExposureBias(bias);
	};

	this.getExposureBias = function () {
		return _exposureBias;
	};

	//Required for switching camera for stereo rendering
	this.setCamera = function (camera) {
		_camera = camera;
	};

	this.setTonemapMethod = function (value) {

		_tonemapMethod  = value;

		if (value === 0) {
		/*
			if (_settings.useHdrTarget) {
				//reallocate the render target if we are going from hdr to ldr
				_settings.useHdrTarget = false;
				this.setSize(_w, _h, true);
			}
			*/
			_renderer.gammaInput = false;
		}
		else {
		/*
			if (!_settings.useHdrTarget) {
				//reallocate the render target if we are going from hdr to ldr
				_settings.useHdrTarget = true;
				this.setSize(_w, _h, true);
			}
		*/
			//Tell the renderer to linearize all material colors
			_renderer.gammaInput = true;
		}

		_renderer.getEnvMapPass().setTonemapMethod(value);

	};

	this.getToneMapMethod = function () {
		return _tonemapMethod;
	};

	this.toggleTwoSided = function (isTwoSided) {
		_settings.haveTwoSided = isTwoSided;
	};

	this.toggleEdges = function(state) {
		_settings.renderEdges = state;
		_oldSettings.renderEdges = state; // avoid settings from outside to be overwritten if triggered before exit2DMode switch.
	};

	this.toggleEnvMapBackground = function (value) {
		_settings.envMapBg = value;
	};

	//Returns the value of the ID buffer at the given
	//viewport location. Note that the viewport location is in
	//OpenGL-style coordinates [-1, 1] range.
	//If the optional third parameter is passed in, it's assume to be a two integer array-like,
	//and the extended result of the hit test (including model ID) is stored in it.
	this.idAtPixel = function (vpx, vpy, res) {
		return this.idAtPixels(vpx, vpy, 1, res);
	};

	// Helper function to copy array values
	function copyArray(srcArray, dstArray) {
		if (!srcArray || !dstArray) {
			return;
		}

		// Clean dst array.
		dstArray.length = 0;

		for (let i = 0; i < srcArray.length; i++) {
			dstArray[i] = srcArray[i];
		}
	}

	// Start the search at the center of the region and then spiral.
	function spiral(px, py, size, readbackBuffer, readbackBuffer2, result) {

		let id;
		let x = 0, y = 0;
		let dx = 0, dy = -1;
		let targetSize = _renderer.getRenderTargets().getTargetSize();


		// Set initial values for result.
		// Result structure: [dbId, modelId, vpx, vpy, px, py]
		// vpx & vpy are the viewport hit coordinates.
		// px & py are the original center point in client coordinates - used for caching purposes.
		_lastIdAtPixelsResults[size] = [-1, -1, null, null, px, py];

		for (let i = 0; i < size * size; i++) {

			// Translate coordinates with top left as (0, 0)
			const tx = x + (size - 1) / 2;
			const ty = y + (size - 1) / 2;
			if (tx >= 0 && tx <= size && ty >= 0 && ty <= size) {
				const index = tx + ty * size;
				const off = index * 4;
				id = (readbackBuffer[off + 3] << 24) | (readbackBuffer[off + 2] << 16) | (readbackBuffer[off + 1] << 8) | readbackBuffer[off];

				_lastIdAtPixelsResults[size][0] = id;

				if(readbackBuffer2) {
					let modelId = (readbackBuffer2[off + 1] << 8) | readbackBuffer2[off];
					//recover negative values when going from 16 -> 32 bits.
					_lastIdAtPixelsResults[size][1] = (modelId << 16) >> 16;
				}

				_lastIdAtPixelsResults[size][2] = (px + tx) * 2 / targetSize[0] - 1; // hit x in viewport coords
				_lastIdAtPixelsResults[size][3] = -((py + ty) * 2 / targetSize[1] - 1); // hit y in viewport coords

				// dbIds can be also negative (see F2d.currentFakeId). -1 is the only dbId that actually means "none".
				if (id !== -1) {
					break;
				}
			}

			if ( (x == y) || (x < 0 && x == -y) || (x > 0 && x == 1-y) ) {
				const t = dx;
				dx = -dy;
				dy = t;
			}

			x += dx;
			y += dy;
		}

		// Copy cached values to output result array.
		copyArray(_lastIdAtPixelsResults[size], result);

		return id;
	}

	this.idAtPixels = function (vpx, vpy, size, result) {

		// Make sure that size is an odd number. Even numbered size can’t be centered using integers.
		if (size % 2 === 0) {
			size += 1;
		}

		let rt = _renderer.getRenderTargets();
		let sz = rt.getTargetSize();
		const px = ((vpx + 1.0) * 0.5 * sz[0] - (size - 1) * 0.5) | 0;

		//TODO: viewport Y needs inversion because WebGPU uses y-down when reading from render targets
		const py = ((-vpy + 1.0) * 0.5 * sz[1] - (size - 1) * 0.5) | 0;

		if (_lastIdAtPixelsResults[size] && px === _lastIdAtPixelsResults[size][4] && py === _lastIdAtPixelsResults[size][5]) {

			// Copy cached values to output result array.
			copyArray(_lastIdAtPixelsResults[size], result);

			// Return cached ID.
			return _lastIdAtPixelsResults[size][0];
		}

		const bufferSize = 4 * size * size;

		if (!_idReadbackBuffers[bufferSize]) {
			_idReadbackBuffers[bufferSize] = new Uint8Array(bufferSize);
		}

		const readbackBuffer = _idReadbackBuffers[bufferSize];

		let readbackBuffer2;
		if (!_modelIdReadbackBuffers[bufferSize]) {
			_modelIdReadbackBuffers[bufferSize] = new Uint8Array(bufferSize);
		}
		readbackBuffer2 = _modelIdReadbackBuffers[bufferSize];

		//TODO: we need to expose the async API in an optional way and then transition code that
		//can work asynchronously to use it.
		// if (false) {
		// 	return rt.readIdTargetPixelsAsync(px, py, size, size, [readbackBuffer, readbackBuffer2]).then(() => {
		// 		return spiral(px, py, size, readbackBuffer, readbackBuffer2, result);
		// 	});
		// } else {
			rt.readIdTargetPixelsSyncOrFail(px, py, size, size, [readbackBuffer, readbackBuffer2]);
			return spiral(px, py, size, readbackBuffer, readbackBuffer2, result);
		//}
	};

	/**
	 * {Number} vpx - OpenGL style X-coordinate [-1..1]
	 * {Number} vpy - OpenGL style Y-coordinate [-1..1]
	 */
	this.rolloverObjectViewport = function (vpx, vpy) {
		//_idRes[1] = 0; // Reset model-id to 0
		this.idAtPixel(vpx, vpy, _idRes);
		return this.rolloverObjectId(_idRes[0], null, _idRes[1]);
	};

    this.getRollOverDbId = function() {
        return _lastHighlightId;
    };

    this.getRollOverModelId = function() {
        return _lastHighlightModelId;
    };

	// Update BlendShader configuration to specify which modelId(s)
	// are shown with rollOver highlight.
	function setHighlightModelId(modelId) {

		// No change => no work.
		if (modelId === _lastHighlightModelId) {
			return false;
		}
		_lastHighlightModelId = modelId;

		_renderer.getBlendSettings().setHighlightModelId(modelId);

		return true;
	}

	// Configure BlendShader for highlighting the given object id
	function setHighlightObjectId(objId) {

		// No change => no work.
		if (objId === _lastHighlightId) {
			return false;
		}
		_lastHighlightId = objId;

		//console.log(objId, modelId);

		//Check if nothing was at that pixel -- 0 means object
		//that has no ID, ffffff (-1) means background, and both result
		//in no highlight.
		if (objId === -1) {
			objId = 0;
		}

		_renderer.getBlendSettings().setHighlightObjectId(objId);

		return true;
	}

	// Configure rollover highlighting for objects or models
	//  @param {number}          objId
	//  @param {number|number[]} modelId            - One or multiple modelIds to be highlighted.
	//  @param {bool}            highlightFullModel - If true, the whole model is highlighted and the obId is ignored.
	function setRolloverHighlight(objId, modelId, highlightFullModel) {

		// An undefined modelId may happen if a) there is no MODEL_ID buffer or b) nothing is highlighted.
		modelId = modelId || 0;

		// apply new objId and modelId
		const objChanged = setHighlightObjectId(objId);
		const modelChanged = setHighlightModelId(modelId);

		// Only restart highlight fade on actual changes
		if (!objChanged && !modelChanged) {
			return;
		}

		_lastObjChanged = true;

		_renderer.getBlendSettings().setHighlightIntensity(0);

		_lastObjTime = performance.now();

		return true;
	}

	/**
	 * {Number} objId - Main Integer id to highlight. If it's not a leaf node,
	 *                  then the dbIds (presumable all its children) will also be highlighed, too.
	 * {Number} [dbIds] - OPTIONAL, id range to highlight.
	 * {Number} [modelId] - OPTIONAL, id of the model containing the id range.
	 */
	this.rolloverObjectId = function(objId, dbIds, modelId) {
		//console.log("rollover", objId, dbIds, modelId);
		setGlowFlag(0);
		return setRolloverHighlight(objId, modelId, false);
	};

	// Roll-over highlighting for whole model. Requires modelId buffer.
	//  @param {number|number[]} modelId - One or more models to highlight.
	this.rollOverModelId = function(modelId) {
		setGlowFlag(0);
		return setRolloverHighlight(1, modelId, true);
	};

	// Note: Colored highlighting is currently only implemented for 3D. For 3D models, it has no effect.
	//
	// @param {THREE.Color} color - default is white
	// The color that is added to the actual fragment color on hover.
	// Default is white. Choosing a darker color reduces highlighting intensity.
	this.setRollOverHighlightColor = function(color) {
		_renderer.getBlendSettings().setRolloverHighlightColor(color);
	};

	this.setDbIdForEdgeDetection = function(objId, modelId) {
		_renderer.getBlendSettings().setEdgeHighlightObjectId(objId, modelId);
	};


	function setGlowFlag(flag) {
		_renderer.getBlendSettings().setGlowFlag(flag);
		_lastGlowFlag = flag;
	}

	/**
	 * @param {Number} flag
	 * @param {THREE.Color} color
	 * @param {Number} compFunc
	 */
	this.setGlowFlagAndColor = function(flag, color, compFunc) {

		if (flag) {

			//cancel any object highlight -- glow effect and single object highlight are
			//mutually exclusive right now
			setRolloverHighlight(0,0,false);

			if (flag !== _lastGlowFlag) {
				setGlowFlag(flag);
				_lastObjTime = performance.now();
			}
		} else {
			setGlowFlag(0);
		}

		_renderer.getBlendSettings().setGlowOptions(color, compFunc || 0);
	};

	this.setEdgeColor = function(colorAsVec4) {
		//Does nothing -- handled by the MainPass/OverlayPass internally
		//_edgeColor = colorAsVec4;
	};

	/** @param {THREE.ColorRepresentation} color */
	this.setSelectionColor = function(color) {
		// The selection color is gamma corrected using 2.0.
		var gamma = new THREE.Color(color);
		gamma.r = Math.pow(gamma.r, 2.0);
		gamma.g = Math.pow(gamma.g, 2.0);
		gamma.b = Math.pow(gamma.b, 2.0);
		_renderer.getBlendSettings().setSelectionColor(gamma);
		_settings.selectionColor = color;
	};

	this.setUnitScale = function(metersPerUnit) {
		let scaleFactor = _unitScale / metersPerUnit;
		_unitScale = metersPerUnit;

		_renderer.getSAO().setUnitScale(scaleFactor);

		//console.log("change of scale", _saoPass.uniforms[ 'radius' ].value);
	};

	this.getUnitScale = function() {
		return _unitScale;
	};

	this.getColorTarget = function() {
		const rt = _renderer.getRenderTargets();
		return rt.getColorTarget();
	};

	/**
	 * @returns {GPUTexture}
	 */
	this.getOverlayTarget = function() {
		const rt = _renderer.getRenderTargets();
		return rt.getOverlayTarget();
	};

	/**
	 * @returns {GPUTexture} WebGPU texture
	 */
	this.getIdTarget = function(index = 0) {
		const rt = _renderer.getRenderTargets();
		return rt.getIdTarget(index);
	};

	/**
	* @returns {GPUTexture} Returns depth target used as input texture for rgb10-packed depth.
	*/
	this.getViewDepthTarget = function() {
		const rt = _renderer.getRenderTargets();
		return rt.getViewDepthTarget();
	};

	/**
	 * @returns {GPUTexture|null}
	 */
	this.getOffscreenTarget = function() {
		return _renderer.getOffscreenTarget();
	}

	/**
	 * @param {GPUTexture|null} target
	 */
	this.setOffscreenTarget = function(target) {
		_renderer.setOffscreenTarget(target);
	}

	/**
	 * @returns {WebGLFramebuffer} Currently bound framebuffer for this context
	 */
	this.getCurrentFramebuffer = function() {
		return _renderer.getCurrentFramebuffer();
	};

	/**
	 * @param {Vector4[]} planes
	 */
	this.setCutPlanes = function(planes) {
		_renderer.getIBL().setCutPlanes(planes);
	};

	// Returns a state object combines various configuration settings that may be modified from outside.
	this.getConfig = function() {

		let clearColors = _renderer.getGradientPass().getClearColors();

		return {
			renderEdges:         _settings.renderEdges,
			envMapBackground:    _settings.envMapBg,
			envMap:              _renderer.getEnvMapPass().getCubeMap(),
			envExposure:         _exposure,
			toneMapExposureBias: _exposureBias,
			envRotation:         this.getEnvRotation(),
			tonemapMethod:       _tonemapMethod,
			clearColorTop:       new THREE.Vector3(clearColors[0], clearColors[1], clearColors[2]),
			clearColorBottom:    new THREE.Vector3(clearColors[3], clearColors[4], clearColors[5]),
			clearAlpha:          _clearAlpha,
			useOverlayAlpha:     _useOverlayAlpha,
			aoEnabled:           this.getAOEnabled(),
			aoRadius:            this.getAORadius(),
			aoIntensity:         this.getAOIntensity(),
			twoSided:            _settings.haveTwoSided,
			unitScale:           this.getUnitScale(),
			antialias:           this.getAntialiasing(),
			selectionColor:      _settings.selectionColor
		};
	};

	this.applyConfig = function(config) {
		this.toggleEdges(config.renderEdges);
		this.toggleEnvMapBackground(config.envMapBackground);
		this.setCubeMap(config.envMap);
		this.setEnvExposure(config.envExposure);
		this.setTonemapExposureBias(config.toneMapExposureBias);
		this.setEnvRotation(config.envRotation);
		this.setTonemapMethod(config.tonemapMethod);
		this.toggleTwoSided(config.twoSided);
		this.setEdgeColor(config.edgeColor);
		this.setUnitScale(config.unitScale);
		this.setAOOptions(config.aoRadius, config.aoIntensity);

		if (config.clearColor) {
			this.setClearColors(config.clearColor);
		} else {
			this.setClearColors(config.clearColorTop, config.clearColorBottom);
		}
		this.setClearAlpha(config.clearAlpha);
		this.useOverlayAlpha(config.useOverlayAlpha);

		// Toggling SAO or antialiasing needs to reinitialize post pipeline.
		// Note: In theory, it may happen that initPostPipeline runs twice if there
		//       was already a 2D/3D mode switch above. But that's not really a frequent case.
		var saoChanged       = (config.aoEnabled != this.getAOEnabled());
		var antialiasChanged = (config.antialias != this.getAntialiasing());
		if (saoChanged || antialiasChanged) {
			this.initPostPipeline(config.aoEnabled, config.antialias);
		}
	};

	/**
	 * @param {THREE.Material|undefined} idMaterial
	 * @param {THREE.Color} selectionColor
	 */
	this.enter2DMode = function (idMaterial, selectionColor) {
		// Note: Some differences to the WebGL case here:
		//   - idMaterial is ignored: It was only needed for the "No MRT-support" case, which we can ignore in WebGPU
		//   - It ensured that idBuffer select is active (which is currently always the case anyway)
		_oldSettings.sao = _settings.sao;
		_oldSettings.antialias = _settings.antialias;
		_oldSettings.renderEdges = _settings.renderEdges;
		_oldSettings.selectionColor = _settings.selectionColor;

		this.setSelectionColor(selectionColor);
		// Note: If edges are active, the edge rendering pass assumes all main scene geometry provides edge indices.
		// Any geometry without edge indices would just re-rendered using the edge shader - which just results in artifacts.
		// Therefore, disable edge rendering for 2D mode.
		_settings.renderEdges = false;

		_renderer.getBlendSettings().setIs2d(true);

		this.initPostPipeline(false, false);
	};

	this.exit2DMode = function () {
		_settings.renderEdges = _oldSettings.renderEdges;

		if (_oldSettings.selectionColor) {
			this.setSelectionColor(_oldSettings.selectionColor);
		}

		_renderer.getBlendSettings().setIs2d(false);

		this.initPostPipeline(_oldSettings.sao, _oldSettings.antialias);
	};

    this.getBlendHighlightIntensity = function() {
        return _renderer.getBlendSettings().getHighlightIntensity();
    };

    this.setBlendHighlightIntensity = function(intensity) {
        _renderer.getBlendSettings().setHighlightIntensity(intensity);
    };

    this.spatialFilterForRollOverSupported = function() {
        return _settings.sao;
    };

    this.setSpatialFilterForRollOver = function(filter, zRange) {
        if (zRange !== undefined && !this.spatialFilterForRollOverSupported()) {
            console.warn('Spatial filter for mouse-over not supported');
            return;
        }
		_renderer.getPostPass().enableSpatialFilter();
        _renderer.getBlendSettings().setZRange(...zRange);
    };

	this.disableSpatialFilterForRollOver = function() {
		_renderer.getPostPass().disableSpatialFilter();
	}

	// Workaround helper for tests: readIdTargetPixelsSyncOrFail() may fail,
	// so that, e.g., a scripted 2D selection may fail if the id buffer isn't cached yet.
	// The returned promise resolves if any pending or outstanding idBuffer readback is finished.
	//
	// Note: This function onyl make sure that pending readbacks are finished.
	//       So, sync id readback is only guaranteed to work afterwards as long as
	//       no changes (camera/model/visibility) happen after calling this function that invalidate the id buffer .
	//
	// It's not great and rather a workaround, but the async problems are a known major TechDebt issue that is out-of-scope short-term (see VIZX-XXX)
	this.waitForIdReadback = function() {
		// make sure that caching readback is initiated. This usually happens already on first mouse-hover,
		// but in a scripted test scenario, the readback may never happen otherwise.
		const targets =_renderer.getRenderTargets();
		const success = targets.readIdTargetPixelsSyncOrFail(0, 0, 1, 1);
		if (success) {
			// Best case - cache is ready to read
			return Promise.resolve();
		}

		// Unlikely, but in case that a previous call is waiting already, just return same promise.
		if (this._waitForIdReadbackPromise) {
			return _waitForIdReadbackPromise;
		}

		const deferred = Promise.withResolvers();
		targets.onIdReadbackCacheFinished = deferred.resolve;

		const promise = deferred.promise.then(() => {
			// cleanup afterwards
			targets.onIdReadbackCacheFinished = null;
			this._waitingForIdReadback = null;
		});
		this._waitingForIdReadback = promise;
		return promise;
	};

	/**
	 * Starts a GPU timing query.
	 * @returns {GPUQuerySet} The query object or command encoder for the timing query.
	 */
	this.startTimeQuery = function() {
		// TODO: Implement these methods for WebGPU
	};

	/**
	 * Stops a GPU timing query.
	 * @param {GPUQuerySet} query The query object or command encoder to stop the timing query.
	 */
	this.stopTimeQuery = function(query) {
		// TODO: Implement these methods for WebGPU
	};

	/**
	 * Retrieves the results of a GPU timing query.
	 * @param {GPUQuerySet} query The query object or buffer containing the timing results.
	 * @param {function} callback The callback function to call with the timing results.
	 */
	this.getTimeResult = function(query, callback) {
		// TODO: Implement these methods for WebGPU
	};

	/**
	 * Canvas with a webgpu context and texture.
	 * @typedef {Object} WebGpuCanvas
	 * @property {HTMLCanvasElement} canvas - Canvas with a webpgu context
	 * @property {GPUTexture} texture - The canvas's texture
	 * @property {function()} cleanup - This must be called when finished with
	 *     the canvas. It will cleanup the webgpu canvas context and texture.
	 */

	/**
	 * Use Renderer's GPUDevice to create a WebGPU canvas.
	 * @param {number} w
	 * @param {number} h
	 * @returns {WebGpuCanvas}
	 */
	this.createWebGpuCanvas = function(w, h) {
		var tmpCanvas = getGlobal().document.createElement('canvas');
		tmpCanvas.width = w;
		tmpCanvas.height = h;

		/** @type {GPUCanvasContext} */
		const context = tmpCanvas.getContext("webgpu");
		context.configure({
			device: _renderer.getDevice(),
			format: navigator.gpu.getPreferredCanvasFormat(),
			usage: GPUTextureUsage.RENDER_ATTACHMENT |
				GPUTextureUsage.COPY_SRC | GPUTextureUsage.COPY_DST,
			alphaMode: 'premultiplied',
		});
		return {
			canvas: tmpCanvas,
			texture: context.getCurrentTexture(),
			cleanup: () => {
				context.unconfigure();
			},
		};
	}

	/**
	 * Copies a WebGPU target into a temporary WebGPU canvas.
	 * @param {GPUTexture} target
	 * @returns {Promise<WebGpuCanvas>}
	 */
	this.targetToCanvas = async function(target) {
		const dst = this.createWebGpuCanvas(target.width, target.height);

		const device = _renderer.getDevice();
		const encoder = device.createCommandEncoder();
		encoder.copyTextureToTexture(
			{ texture: target, origin: [0, 0, 0] },
			{ texture: dst.texture, origin: [0, 0, 0] },
			[target.width, target.height]);

		device.queue.submit([encoder.finish()]);
		await device.queue.onSubmittedWorkDone();

		return dst;
	};
}

RenderContextWebGPU.prototype = Object.create(RenderContextBase.prototype);