/** @import { OutOfCoreTileManager } from "../OutOfCoreTileManager" */

/**
 * Base class for out of core tasks
 */
export class OutOfCoreTaskBase {

    /** @type {OutOfCoreTileManager} */
    outOfCoreTileManager;

    /**
     * @param {OutOfCoreTileManager} outOfCoreTileManager The OutOfCoreTileManager instance creating this task
    */
    constructor(outOfCoreTileManager) {
        this.outOfCoreTileManager = outOfCoreTileManager;
    }

    /**
     * Executes the task
     * @returns {number|undefined} The memory consumed when executing the task, undefined if no consolidation is available
     */
    execute() {
        return 0;
    }

    /**
     * Frees the memory consumed by the task
     * @returns {number} The memory that could be freed
     */
    freeMemory() {
        return 0;
    }

    /**
     * Returns the memory cost of the task
     * @returns The memory cost of the task
     */
    getMemoryCost() {
        return 0;
    }

    /**
     * Returns the memory that can be freed by this task
     * @param {Object} scratchpad - Used to share information with other tasks to accurately determine the memory that can be freed
     * @returns {number} The memory that can be freed by this task
     */
    getFreeableMemory(scratchpad) {
        return 0;
    }
}