
import THREE from "three";

export function blendParamThreeToGPU(p) {
	switch (p) {
		case THREE.AddEquation: return "add";
		case THREE.MinEquation: return "min";
		case THREE.MaxEquation: return "max";
		case THREE.SubtractEquation: return "subtract";
		case THREE.ReverseSubtractEquation: return "reverse-subtract";

		case THREE.ZeroFactor: return "zero";
		case THREE.OneFactor: return "one";
		case THREE.SrcColorFactor: return "src";
		case THREE.OneMinusSrcColorFactor: return "one-minus-src";
		case THREE.SrcAlphaFactor: return "src-alpha";
		case THREE.OneMinusSrcAlphaFactor: return "one-minus-src-alpha";
		case THREE.DstAlphaFactor: return "dst-alpha";
		case THREE.OneMinusDstAlphaFactor: return "one-minus-dst-alpha";

		case THREE.DstColorFactor: return "dst";
		case THREE.OneMinusDstColorFactor: return "one-minus-dst";
		case THREE.SrcAlphaSaturateFactor: return "src-alpha-saturated";

		default: console.warn("unknown blend param", p); return "";
	}
}

export function filterParamThreeToGPU(p, isForMipMap) {

	switch (p) {

		case THREE.RepeatWrapping: return "repeat";
		case THREE.ClampToEdgeWrapping: return "clamp-to-edge";
		case THREE.MirroredRepeatWrapping: return "mirror-repeat";

		case THREE.NearestFilter: return "nearest";
		case THREE.NearestMipMapNearestFilter: return "nearest";
		case THREE.NearestMipMapLinearFilter: return isForMipMap ? "linear" : "nearest";

		case THREE.LinearFilter: return "linear";
		case THREE.LinearMipMapNearestFilter: return isForMipMap ? "nearest" : "linear";
		case THREE.LinearMipMapLinearFilter: return "linear";

		default: console.warn("unknown filter param", p); return "";
	}
}


export function paramTexThreeToGL(format, type) {

	let f, t;

	switch (format) {
		case THREE.AlphaFormat: f = "r"; break;
		case THREE.RGBFormat: f = "rgb is unsupported"; break;
		case THREE.RGBAFormat: f = "rgba"; break;
		case THREE.LuminanceFormat: f = "r"; break;
		case THREE.LuminanceAlphaFormat: f = "rg"; break;
		case THREE.RGB_S3TC_DXT1_Format: return "bc1-rgb-unorm unsupported";
		case THREE.RGBA_S3TC_DXT1_Format: return "bc1-rgba-unorm";
		case THREE.RGBA_S3TC_DXT3_Format: return "bc2-rgba-unorm";
		case THREE.RGBA_S3TC_DXT5_Format: return "bc3-rgba-unorm";

		default: f = format; break;
	}

	switch (type) {
		case THREE.UnsignedByteType: t = "8unorm"; break;
		case THREE.ByteType: t = "8snorm"; break;
		case THREE.ShortType: t = "16sint"; break;
		case THREE.UnsignedShortType: t = "16uint"; break;
		case THREE.IntType: t = "32sint"; break;
		case THREE.UnsignedIntType: t = "32uint"; break;
		case THREE.FloatType: t = "32float"; break;
		case THREE.HalfFloatType: t = "16float"; break;

		default: console.warn("unknown type", type); t = "";
	}

	return "" + f + t;

}


export function sideToCullMode(side) {
	switch (side) {
		case THREE.FrontSide: return "back";
		case THREE.BackSide: return "front";
		case THREE.DoubleSide: return "none";
	}
}