
import { theExtensionManager } from "../src/application/ExtensionManager";
import { restrictedExternalExtensions, externalExtensions, getExtensionEntryKey } from './externalExtensions';


const ext = BUILD_FLAG__MINIFIED_BUILD ? 'min.js' : 'js';

if (BUILD_FLAG__DIFF_TOOL) {
    // Not available in externalExtensions.js
    externalExtensions.push({ src: './extensions/DiffTool/DiffTool.js', ids: ['Autodesk.DiffTool'] });
}

// don't bundle extensions that should not be available to external users
if (!BUILD_FLAG__IS_MASTER_BRANCH || BUILD_FLAG__FLUENT_PROFILE) {
    externalExtensions.push(...restrictedExternalExtensions);
}

// Register them all
externalExtensions.forEach((ee)=>{

    let key = getExtensionEntryKey(ee);
    let filePath = `extensions/${key}/${key}.${ext}`;
    let dependencies = ee.dependencies;
    ee.ids.forEach((id)=>{
        theExtensionManager.registerExternalExtension(id, filePath, dependencies);
    });
});
