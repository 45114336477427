import vertexTextureQuad from "./quad.vert.wgsl";
import fxaaShader from "./fxaa.wgsl";
import {getBlendShader} from "./BlendShader";
import {BlendSettings, UnProjectSettings} from "./BlendSettings";

/** @import { Renderer } from '../Renderer' */

/**
 * @param {Renderer} renderer
 */
export function BlendPass(renderer) {

	let _renderer = renderer;
	let _device;

	let _presentationFormat = navigator.gpu.getPreferredCanvasFormat();

	let _blendSettings = new BlendSettings();
	let _unProjectSettings = new UnProjectSettings();
	let _blendPipeline;
	// used for rollover highlighting of Levels
	let _blendPipelineWithSpatialFilter;
	let _fxaaPipeline;
	let _blendBindGroupLayout;
	let _fxaaBindGroupLayout;
	let _blendPassDescriptor;

	let _blendTexBindGroup;
	let _postBindGroups = [];
	let _postTargetViews = [];
	let _sampler;

	let _spatialFilterEnabled = false;

	this.init = function() {
		_device = _renderer.getDevice();
		_unProjectSettings.init(_device);
		_blendSettings.init(_device);
	};

	this.getBlendSettings = function() {
		return _blendSettings;
	};

	function createPostTargets(w, h) {

		//This needs to be recreated when render targets change size
		for (let i=0; i<2; i++) {

			const postTarget = _renderer.getRenderTargets().getPostTarget(i);
			_postTargetViews[i] = postTarget.createView();
			_postTargetViews[i].label = `view:${postTarget.label}`;

			_postBindGroups[i] = _device.createBindGroup({
				layout: _fxaaBindGroupLayout,
				entries: [
					{
						binding: 0,
						resource: _postTargetViews[i]
					},
					{
						binding: 1,
						resource: _sampler
					}
				]
			});
		}

	}

	function createFXAAPass(w, h) {
		if (!_fxaaBindGroupLayout) {
			_fxaaBindGroupLayout = _device.createBindGroupLayout({
				entries: [
					{
						binding: 0,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'float'
						}
					},
					{
						binding: 1,
						visibility: GPUShaderStage.FRAGMENT,
						sampler: {}
					},
				]
			});
		}

		//Needs to be recompiled when size changes since resolution is
		//in a shader constant
		_fxaaPipeline = _device.createRenderPipeline({
			layout: _device.createPipelineLayout({
				bindGroupLayouts: [_fxaaBindGroupLayout]
			}),
			vertex: {
				module: _device.createShaderModule({ code: vertexTextureQuad }),
				entryPoint: "mainFlipY"
			},
			fragment: {
				module: _device.createShaderModule({ code: fxaaShader }),
				entryPoint: "FxaaPixelShader",
				targets: [
					{
						format: _presentationFormat
					}
				],
				constants: {
					0: 1.0 / w,
					1: 1.0 / h
				}
			},
			primitive: {
				topology: 'triangle-list',
				cullMode: 'back',
			}
		});


		if (!_sampler) {
			_sampler = _device.createSampler({
				magFilter: "linear",
				minFilter: "linear",
			});
		}

	}

	function _createBlendPipeline(enableSpatialFilter = false) {
		const blendSettingsLayout = _blendSettings.getLayout();
		const unProjectSettingsLayout = _unProjectSettings.getLayout();
		blendSettingsLayout.label = 'blend-pass-settings';
		unProjectSettingsLayout.label = 'blend-pass-unproject';

		return _device.createRenderPipeline({
			label: `blend-pass`,
			layout: _device.createPipelineLayout({
				bindGroupLayouts: [
					_blendBindGroupLayout,
					blendSettingsLayout,
					unProjectSettingsLayout
				]
			}),
			vertex: {
				module: _device.createShaderModule({ label: 'blend-vertex-shader', code: vertexTextureQuad }),
				entryPoint: "main"
			},
			fragment: {
				module: _device.createShaderModule({ label: 'blend-fragment-shader', code: getBlendShader(enableSpatialFilter) }),
				entryPoint: "main",
				targets: [
					{
						format: _presentationFormat
					}
				]
			},
			primitive: {
				topology: 'triangle-list',
				cullMode: 'back',
			}
		});
	}

	function createBlendPass() {

		if (!_blendBindGroupLayout) {
			_blendBindGroupLayout = _device.createBindGroupLayout({
				entries: [
					{
						binding: 0,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'float'
						}
					},
					{
						binding: 1,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'float'
						}
					},
					{
						binding: 2,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'uint'
						}
					},
					{
						binding: 3,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'uint'
						}
					},
					/*{
						binding: 4,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'uint'
						}
					},*/
					{
						binding: 4,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'float'
						}
					},
					{
						binding: 5,
						visibility: GPUShaderStage.FRAGMENT,
						texture: {
							sampleType: 'depth'
						}
					},
				]
			});
		}

		if (!_blendPipeline) {
			_blendPipeline = _createBlendPipeline();
		}

		if (!_blendPipelineWithSpatialFilter) {
			_blendPipelineWithSpatialFilter = _createBlendPipeline(true);
		}

		//This needs to be recreated when render targets change size
		let entries = _renderer.getRenderTargets().getTargetViewsForBlend().map((resource, binding) => ({ binding, resource }));

		entries.push({ binding: entries.length, resource: _renderer.getSAO().getTargetView() });

		const depth = _renderer.getRenderTargets().getDepthTarget();
        entries.push({ binding: entries.length /* 5 */, label: `view:${depth.label}`, resource: depth.createView() });

		_blendTexBindGroup = _device.createBindGroup({
			label: `blend-pass-textures`,
			layout: _blendBindGroupLayout,
			entries
		});

		//console.log(_blendTexBindGroup);

		if (!_blendPassDescriptor) {
			_blendPassDescriptor = {
				colorAttachments: [
					{
						// view is acquired and set in render loop.
						view: undefined,

						clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 1.0 },
						loadOp: 'clear',
						storeOp: 'store',
					},
				],

			};
		}
	}

	this.resize = function(w, h) {
		createFXAAPass(w, h);
		createBlendPass();
		createPostTargets(w, h);
	};

	this.run = function(targetView, antialias, camera) {
		_blendSettings.upload();

		if (_spatialFilterEnabled) {
			_unProjectSettings.setCamera(camera);
			_unProjectSettings.upload();
		}

		let commandEncoder = _device.createCommandEncoder();

		if (!antialias) {
			_blendPassDescriptor.colorAttachments[0].view = targetView;

			//Blend pass directly into screen target
			let pass = commandEncoder.beginRenderPass(_blendPassDescriptor);
			pass.setPipeline(_spatialFilterEnabled ? _blendPipelineWithSpatialFilter : _blendPipeline);
			pass.setBindGroup(0, _blendTexBindGroup);
			pass.setBindGroup(1, _blendSettings.getBindGroup());
			pass.setBindGroup(2, _unProjectSettings.getBindGroup());
			pass.draw(3);
			pass.end();
		} else {

			_blendPassDescriptor.colorAttachments[0].view = _postTargetViews[0];

			//Blend pass into Post0 target
			let pass = commandEncoder.beginRenderPass(_blendPassDescriptor);
			pass.setPipeline(_spatialFilterEnabled ? _blendPipelineWithSpatialFilter : _blendPipeline);
			pass.setBindGroup(0, _blendTexBindGroup);
			pass.setBindGroup(1, _blendSettings.getBindGroup());
			pass.setBindGroup(2, _unProjectSettings.getBindGroup());
			pass.draw(3);
			pass.end();

			//FXAA from Post0 to screen
			_blendPassDescriptor.colorAttachments[0].view = targetView;
			pass = commandEncoder.beginRenderPass(_blendPassDescriptor);
			pass.setPipeline(_fxaaPipeline);
			pass.setBindGroup(0, _postBindGroups[0]);
			pass.draw(3);
			pass.end();

		}

		// _blendSettings.copyBufferToRead(commandEncoder);


		_device.queue.submit([commandEncoder.finish()]);
	};

	this.enableSpatialFilter = function() {
		_spatialFilterEnabled = true;
	}

	this.disableSpatialFilter = function() {
		_spatialFilterEnabled = false;
	}

}
