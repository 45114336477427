
import  "core-js";
import { default as prefix, adsk_export } from "./module-prefix";

import _THREE from 'three';
import * as Viewing from './imports/av.js';

export const Autodesk = prefix.Autodesk;
export const av = {};
export const avp = {};
export const avu = {};
export const ave = {};
export const THREE = _THREE;

const exports = {
    Autodesk,
    av,
    avp,
    avu,
    ave,
    THREE
};
adsk_export(exports, Viewing.Private, 'avp');
adsk_export(exports, Viewing.UI, 'avu');
adsk_export(exports, Viewing.Extensions, 'ave');
adsk_export(exports, Viewing, 'av', ['Private', 'UI', 'Extensions']);
export default exports;

import { BUILD_FLAG__BUILD_TYPE } from './buildFlags.js';
if (BUILD_FLAG__BUILD_TYPE === 'NodeJs') {
    adsk_export(require("./file-loaders/lmvtk/otg/OtgWebSocket"), "avp");
}

import * as moduleSuffix from "./module-suffix";
moduleSuffix.initializeGlobalNamespaces(exports);

// MaterialConverterPrism needs to be bundled for nodejs build
// for DiffTool unit tests that load an svf
if (BUILD_FLAG__BUILD_TYPE === 'NodeJs') {
    // MaterialConverterPrism uses av.xxx, which is only available after the call to initializeGlobalNamespaces above
    const MaterialConverterPrism = require("../extensions/MaterialConverterPrism/MaterialConverterPrism");
    adsk_export(exports, { MaterialConverterPrism }, "av");
    Autodesk.Viewing['MaterialConverterPrism'] = MaterialConverterPrism;
}
