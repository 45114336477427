export class RenderContextBase {
    constructor() {
        // Make sure the abstract class is not directly instantiated
        if (new.target === RenderContextBase) {
            throw new TypeError('Cannot construct RenderContextBase instances directly');
        }
    }

    /**
     * Initializes the render context.
     * Must be implemented by subclasses.
     * @abstract
     * @param {any} renderer The renderer object.
     * @param {number} width The width of the render context.
     * @param {number} height The height of the render context.
     * @param {object} options The options for the render context.
     */
    init(renderer, width, height, options = {}) {
        throw new Error('init method must be implemented');
    }

    /**
     * Starts a GPU timing query.
     * Must be implemented by subclasses.
     * @abstract
     * @returns {any} The query object or command encoder for the timing query.
     */
    startTimeQuery() {
        throw new Error('startTimeQuery method must be implemented');
    }

    /**
     * Stops a GPU timing query.
     * Must be implemented by subclasses.
     * @abstract
     * @param {any} query The query object or command encoder to stop the timing query.
     */
    stopTimeQuery(query) {
        throw new Error('stopTimeQuery method must be implemented');
    }

    /**
     * Retrieves the results of a GPU timing query.
     * Must be implemented by subclasses.
     * @abstract
     * @param {any} query The query object or buffer containing the timing results.
     * @param {function} callback The callback function to call with the timing results.
     */
    getTimeResult(query, callback) {
        throw new Error('getTimeResult method must be implemented');
    }
}