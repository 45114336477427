import { MeshFlags }  from '../../../scene/MeshFlags.js';
import { LmvMatrix4 } from '../../../scene/LmvMatrix4.js';
import {$wgsl} from "../../../wgsl-preprocessor/wgsl-preprocessor";
import objectUniforms from "../object_uniforms.wgsl";

// Stride size of a single entry in the ObjectUniforms buffer
export const OBJECT_STRIDE = 96;                   // in bytes
export const OBJECT_STRIDE_32 = OBJECT_STRIDE / 4; // in floats

export function getObjectUniformsDeclaration(bindGroup) {
	return $wgsl(objectUniforms, { bindGroup });
}

// Memory layout of the ObjectUniforms for a single fragment in the batched GPU UniformBuffer
// All offsets are defined in 32-bit Items (float/int)
export const UniformOffsets = {
    transform:         0,   // 16 floats (TODO: should be optimized)
    dbId:              16,  // All reamaining values are Int32
    modelId:           17,
    flags:             18,
    themingColor:      19,
    materialReference: 20
};

// This class defines the way how per-fragment uniforms are batched into a single float32 UniformBuffers.
// It is used by ObjectUniformBuilder to create and GPU-upload ranges of Uniforms for multiple fragments.
export class ObjectUniforms {

    // Different views to the CPU-side buffer
    /** @type Float32Array */
    #data;
    /** @type Int32Array */
    #dataInt;
    /** @type Uint8Array */
    #dataUint8;

    #tmpMtx = new LmvMatrix4(false);

    /**
     * @param {number} capacity - maximum number of items
     */
    constructor(capacity) {
        // CPU-side buffer for temporary storage before upload
        this.#data      = new Float32Array(OBJECT_STRIDE_32 * capacity);
        this.#dataInt   = new Int32Array(this.#data.buffer);
        this.#dataUint8 = new Uint8Array(this.#data.buffer);
    }

    // Buffer size in items
    length() {
        return this.#data.length / OBJECT_STRIDE_32;
    }

    // Copy fragment transform data to the CPU buffer.
    #setTransformFromFragment(
        objectIndex, // which object to write to. Must be in range [0, length)
        fragmentList,
        fragId
    ) {
        // If animations or model transform is involved, we cannot directly
        // read the raw transforms.
        const moved      = fragmentList.vizflags[fragId] & MeshFlags.MESH_MOVED;
        const hasModelTf = Boolean(fragmentList.matrix);
        if (moved || hasModelTf) {
            fragmentList.getWorldMatrix(fragId, this.#tmpMtx);
            this.setTransform(objectIndex, this.#tmpMtx);
            return;
        }

        // Faster variant: In most cases, we can read raw transform values
        const transforms = fragmentList.transforms;

        const TRANSFORM_STRIDE = 12;
        const readOffset  = (fragId * TRANSFORM_STRIDE);
        const writeOffset = objectIndex * OBJECT_STRIDE_32; // write start position in floats

        // We only store the upper 3 rows explicitly.
        // The last row is always (0,0,0,1).
        this.#data[writeOffset] = transforms[readOffset];
        this.#data[writeOffset + 1] = transforms[readOffset + 1];
        this.#data[writeOffset + 2] = transforms[readOffset + 2];
        this.#data[writeOffset + 3] = 0;
        this.#data[writeOffset + 4] = transforms[readOffset + 3];
        this.#data[writeOffset + 5] = transforms[readOffset + 4];
        this.#data[writeOffset + 6] = transforms[readOffset + 5];
        this.#data[writeOffset + 7] = 0;
        this.#data[writeOffset + 8] = transforms[readOffset + 6];
        this.#data[writeOffset + 9] = transforms[readOffset + 7];
        this.#data[writeOffset + 10] = transforms[readOffset + 8];
        this.#data[writeOffset + 11] = 0;
        this.#data[writeOffset + 12] = transforms[readOffset + 9];
        this.#data[writeOffset + 13] = transforms[readOffset + 10];
        this.#data[writeOffset + 14] = transforms[readOffset + 11];
        this.#data[writeOffset + 15] = 1;
    }

    // Set from LmvMatrix4 (must be float32)
    setTransform(objectIndex, matrix) {
        const writeOffset = objectIndex * OBJECT_STRIDE_32;
        this.#data.set(matrix.elements, writeOffset);
    }

    /**
     * Set all object uniforms except transform.
     * @param {number} objectIndex array index of the object in the GPUBuffer.
     * @param {number} dbId
     * @param {number} modelId
     * @param {number} objectFlags Reserved for visibility flags - currently not used.
     * @param {number} themingColor Theming color as ABGR integer.
     * @param {number} materialReference Material reference index.
     */
    #setOtherUniforms(objectIndex, dbId, modelId, objectFlags, themingColor, materialReference) {
        const writeOffset = objectIndex * OBJECT_STRIDE_32;
        this.#dataInt[writeOffset + UniformOffsets.dbId]              = dbId;
        this.#dataInt[writeOffset + UniformOffsets.modelId]           = modelId;
        this.#dataInt[writeOffset + UniformOffsets.objectFlags]       = objectFlags;
        this.#dataInt[writeOffset + UniformOffsets.themingColor]      = themingColor;
        this.#dataInt[writeOffset + UniformOffsets.materialReference] = materialReference;
    }

    // write object uniform entry from single fragment
    setFromFragment(objectIndex, fragmentList, fragId, materialReference) {

        const dbId    = fragmentList.fragments.fragId2dbId[fragId];
        const modelId = fragmentList.modelId;
        const objectFlags  = 0; // not used yet. Can be used for visibility later.
        const themingColor = readThemingColor(fragmentList, fragId);

        this.#setTransformFromFragment(objectIndex, fragmentList, fragId);
        this.#setOtherUniforms(objectIndex, dbId, modelId, objectFlags, themingColor, materialReference);
    }

    // Same as setFromFragment, but obtaining the values from a mesh object.
    // Note that only transform is a standard prop - all others usually only exist for meshes
    // obtained via getVizMesh() for 2D models.
    setFromObject(mesh, objectIndex, materialReference) {

        this.setTransform(objectIndex, mesh.matrixWorld);

        // get theming color (may be undefined or ignored if intensity value w is 0)
        const themingColor = mesh.themingColor;
        let themingColorGPU = 0;
        if (themingColor && themingColor.w > 0.0) {
            themingColorGPU = vectorToABGR(mesh.themingColor);
        }

        this.#setOtherUniforms(
            objectIndex,
            mesh.dbId,
            mesh.modelId,
            mesh.objectFlags || 0,
            themingColorGPU,
            materialReference
        );
    }

    /*
     * Set the object data for a range of objects by getting the data from an instanced mesh.
     */
    setObjectDataFromInstanceBuffer(geometry, itemOffset, materialReference) {
        const attrib     = geometry.attributes.instanceUniforms;
        const srcData    = attrib.array;
        const dstData    = this.#dataUint8;
        const byteOffset = itemOffset * OBJECT_STRIDE;
        dstData.set(srcData, byteOffset);

        // fill in (identical) material reference to all instances
        for (let i=0; i<geometry.numInstances; i++) {
            const offset = (itemOffset + i) * OBJECT_STRIDE_32;
            this.setMaterialReference(offset, materialReference);
        }
    }

    // Write only material reference for a single object to CPU-side buffer.
    // Note: Having to use the int32 offset instead of ObjectIndex is just for backwards compatibility
    //       with the old ObjectUniforms. This should later be simplified by another function that just works with objectIndex directly.
    setMaterialReference(
        writeOffset,        // int32 offset
        materialReference
    ) {
        this.#dataInt[writeOffset + UniformOffsets.materialReference] = materialReference;
    }

    // Upload a single int-value
    uploadIntValue(
        device,
        dstBuffer,
        dstObjectIndex, // index of the item in the buffer for which this uniform changed
        attribOffset,   // offset in 32-Bit ints within the item. Determines which uniform is updated, e.g. UniformOffsets.dbId
        value
    ) {
        this.#dataInt[0] = value;

        const writeOffsetGPU = dstObjectIndex * OBJECT_STRIDE + 4 * attribOffset; // in bytes
        device.queue.writeBuffer(dstBuffer, writeOffsetGPU, this.#data.buffer, 0, 4);
    }

    // Upload just a range of bytes (usually a single uniform value, e.g. transform, theming color etc) of the uniform data.
    uploadTransform(
        device,
        dstBuffer,
        dstObjectIndex, // index of the item in the buffer for which this uniform changed
        fragList,
        fragId
    ) {
        // Write fragment transform to the CPU buffer
        this.#setTransformFromFragment(0, fragList, fragId);

        // get offsets in bytes
        const attribOffset   = 4 * UniformOffsets.transform;
        const readOffsetCPU  = attribOffset;
        const writeOffsetGPU = dstObjectIndex * OBJECT_STRIDE + attribOffset;

        // Upload transform
        const byteLength = 64; // 16 floats * 4 bytes
        device.queue.writeBuffer(dstBuffer, writeOffsetGPU, this.#data.buffer, readOffsetCPU, byteLength);
    }

    /**
     * Sets and uploads the theming color uniform for the object at the specified index.
     * @param {GPUDevice} device
     * @param {GPUBuffer} dstBuffer
     * @param {number} dstObjectIndex The index within the GPU buffer of the object to update.
     * @param {THREE.Vector4} color The theming color vector of the fragment.
     */
    uploadThemingColor(device, dstBuffer, dstObjectIndex, color) {

        const value = color.w > 0.0 ? vectorToABGR(color) : 0;
        this.#dataInt[UniformOffsets.themingColor] = value;

        // get offsets in bytes
        const attribOffset   = 4 * UniformOffsets.themingColor;
        const readOffsetCPU  = attribOffset;
        const writeOffsetGPU = dstObjectIndex * OBJECT_STRIDE + attribOffset;

        // Upload theming color
        const byteLength = 4;
        device.queue.writeBuffer(dstBuffer, writeOffsetGPU, this.#data.buffer, readOffsetCPU, byteLength);
    }

    // Upload this buffer (or a range of it) to a GPU buffer.
    upload(device, dstBuffer, count = this.length(), dstStart = 0, srcStart = 0, alignTo256 = false) {
        const byteOffset = dstStart * OBJECT_STRIDE;
        let byteLength   = count    * OBJECT_STRIDE;

        // TODO: This was kept from original code, but it seems unnecessary.
        if (alignTo256) {
            //round up to next multiple of 256
            byteLength = (byteLength + 255) & 0xffffff00;
        }

        device.queue.writeBuffer(dstBuffer, byteOffset, this.#data.buffer, srcStart * OBJECT_STRIDE, byteLength);
    }
}

// Read fragment theming color as ABGR integer (0 if not themed)
function readThemingColor(fragList, fragId) {
    const themingColor = fragList.getThemingColor(fragId);
    const isThemed     = (themingColor && themingColor.w > 0.0);
    return isThemed ? vectorToABGR(themingColor) : 0;
}

export function vectorToABGR(v) {
    return (v.x * 255) | ((v.y * 255) << 8) | ((v.z * 255) << 16) | ((v.w * 255) << 24);
}
