export default function(gantt) {
gantt.skins.terrace = {
	config:{
		grid_width:390,
		row_height: 36,
		scale_height: 36,
		link_line_width:2,
		link_arrow_size:12,
		bar_height_padding:9,
		lightbox_additional_height:75
	},
	_second_column_width:100,
	_third_column_width:70		
};

};