/* eslint-disable no-undef */

/**
 * This file defines and exports build flags.
 * WebPack will replace these strings with their actual values during the build process.
 * However, this replacement won't occur in files not processed by WebPack.
 *
 * To ensure these settings are defined and exported correctly, this file is created.
 * When files are loaded directly by the browser, WebPack will compile this file and replace the symbols here.
 * In other files, the symbols will be imported from this file.
 *
 * Therefore, two separate variable names are used:
 * - One for WebPack to replace during the build process.
 * - One for exporting the symbol.
 */

const _BUILD_FLAG__MINIFIED_BUILD = BUILD_FLAG__MINIFIED_BUILD;
const _BUILD_FLAG__BUILD_VERSION = BUILD_FLAG__BUILD_VERSION;
const _BUILD_FLAG__BUILD_TYPE = BUILD_FLAG__BUILD_TYPE;
const _BUILD_FLAG__IS_MASTER_BRANCH = BUILD_FLAG__IS_MASTER_BRANCH;
const _BUILD_FLAG__LMV_WORKER_FILE = BUILD_FLAG__LMV_WORKER_FILE;
const _BUILD_FLAG__INLINE_WORKER = BUILD_FLAG__INLINE_WORKER;
const _BUILD_FLAG__USE_OTG_DS_PROXY = BUILD_FLAG__USE_OTG_DS_PROXY;

// Features
const _BUILD_FLAG__WANT_GUI = BUILD_FLAG__WANT_GUI;
const _BUILD_FLAG__DIFF_TOOL = BUILD_FLAG__DIFF_TOOL;
const _BUILD_FLAG__FLUENT_PROFILE = BUILD_FLAG__FLUENT_PROFILE;
const _BUILD_FLAG__WASM_SUPPORT = BUILD_FLAG__WASM_SUPPORT;
const _BUILD_FLAG__USE_IDBUFFER_SELECTION_BY_DEFAULT = BUILD_FLAG__USE_IDBUFFER_SELECTION_BY_DEFAULT;

export {
  _BUILD_FLAG__MINIFIED_BUILD as BUILD_FLAG__MINIFIED_BUILD,
  _BUILD_FLAG__BUILD_VERSION as BUILD_FLAG__BUILD_VERSION,
  _BUILD_FLAG__BUILD_TYPE as BUILD_FLAG__BUILD_TYPE,
  _BUILD_FLAG__IS_MASTER_BRANCH as BUILD_FLAG__IS_MASTER_BRANCH,
  _BUILD_FLAG__LMV_WORKER_FILE as BUILD_FLAG__LMV_WORKER_FILE,
  _BUILD_FLAG__INLINE_WORKER as BUILD_FLAG__INLINE_WORKER,
  _BUILD_FLAG__USE_OTG_DS_PROXY as BUILD_FLAG__USE_OTG_DS_PROXY,
  _BUILD_FLAG__WANT_GUI as BUILD_FLAG__WANT_GUI,
  _BUILD_FLAG__DIFF_TOOL as BUILD_FLAG__DIFF_TOOL,
  _BUILD_FLAG__FLUENT_PROFILE as BUILD_FLAG__FLUENT_PROFILE,
  _BUILD_FLAG__WASM_SUPPORT as BUILD_FLAG__WASM_SUPPORT,
  _BUILD_FLAG__USE_IDBUFFER_SELECTION_BY_DEFAULT as BUILD_FLAG__USE_IDBUFFER_SELECTION_BY_DEFAULT
};
