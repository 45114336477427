export default function(gantt) {
gantt.skins["contrast_black"] = {
	config:{
		grid_width:390,
		row_height: 35,
		scale_height: 35,
		link_line_width:2,
		link_arrow_size:12,
		lightbox_additional_height:75
	},
	_second_column_width:100,
	_third_column_width:80
};

};