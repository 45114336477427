/**
 * This file exports events, which are not part of the public API and will not be included in the global namespace
 */


/**
 * Fired if consolidation is enabled for the model.
 * @private
 */
export const MODEL_CONSOLIDATION_ENABLED = 'consolidationEnabled';

/**
 * Fired if consolidation is disabled for the model.
 * @private
 */
export const MODEL_CONSOLIDATION_DISABLED = 'consolidationDisabled';
