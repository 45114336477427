// Use Uniform Buffer Objects in WebGL to render fragments with compatible materials in the same draw call
// Only used with consolidated meshes

import { USE_MULTI_MATERIAL_RENDER_CALLS } from '../globals.js';
import isEqual from 'lodash/isEqual';

export let MAX_FRAGMENTS_PER_CONSOLIDATED_MESH = 1000;
let MAX_MATERIALS_PER_CONSOLIDATED_MESH = 100;

const UBO_MATERIAL_SIZE = {
    'MeshPhongMaterial': 4 * 4,
    'LineBasicMaterial': 4
};

let _maxUniformBlockSize = 0;

const ignoreAttributes = {
    'Common': new Set(['uuid', 'id', 'name', 'uniformsList', 'uniformsLists', 'program', 'programs', 'svfMatId', 'hash', 'tag', 'proteinMat']),
    'LineBasicMaterial': new Set(['opacity', 'color']),
    'MeshPhongMaterial': new Set(['opacity', 'color', 'specular', 'emissive', 'shininess', 'reflectivity', 'ambient'])
};

export class UniformBufferObjectManager {
    #renderer;

    /** @type {Map<THREE.Material, THREE.Material>} */ #multiMaterialDrawingCombinedMaterialCache = new Map();

    constructor(renderer, maxUniformBlockSize) {
        this.#renderer = renderer;
        this.#updateUBOLimits(maxUniformBlockSize);
    }

    dtor() {
        this.#multiMaterialDrawingCombinedMaterialCache.clear();
    }

    /**
     * Prepares the mesh to be rendered with UBOs
     * @param {FragmentList} fragList
     * @param {number[]} fragIds
     * @param {THREE.Mesh} mesh
     */
    prepareUBOs(fragList, fragIds, mesh) {
        const { fragmentData, materials } = this.#createFragmentBuffer(fragList, fragIds, mesh);

        let materialData = this.#createMaterialBuffer(materials);
        this.#prepareUBOMaterial(mesh, materials, fragmentData, materialData);
    }

    #createFragmentBuffer(fragList, fragIds, mesh) {
        let fragmentData = new Uint32Array(4 * MAX_FRAGMENTS_PER_CONSOLIDATED_MESH);
        let materials = [];
        for (let i = 0; i < mesh.rangeCount; i++) {
            let fragId = fragIds[mesh.rangeBegin + i];

            let mat = fragList.getMaterial(fragId);
            let materialIndex = materials.indexOf(mat);
            if (materialIndex === -1) {
                materials.push(mat);
                materialIndex = materials.length - 1;
            }
            let dbID = fragList.getDbIds(fragId);
            fragmentData[4 * i + 0] = materialIndex;
            fragmentData[4 * i + 1] = dbID;
        }

        return { fragmentData, materials };
    }

    #prepareUBOMaterial(mesh, materials, fragmentData, materialData) {
        if (materialData) {
            this.#renderer.setUniformBufferObjects(mesh, fragmentData, materialData);

            if (!mesh.material?.defines?.USE_UNIFORM_BUFFER_OBJECTS) {
                let combinedMaterial = undefined;
                for (let mat of materials) {
                    if (this.#multiMaterialDrawingCombinedMaterialCache.has(mat)) {
                        combinedMaterial = this.#multiMaterialDrawingCombinedMaterialCache.get(mat);
                        break;
                    }
                }

                if (!combinedMaterial) {
                    let oldMaterial = mesh.material;

                    combinedMaterial = mesh.material.clone();
                    for (let key in oldMaterial) {
                        if (oldMaterial[key] !== combinedMaterial[key]) {
                            if (key === 'id') continue;

                            combinedMaterial[key] = oldMaterial[key];
                        }
                    }
                    combinedMaterial.needsUpdate = true;

                    combinedMaterial.defines = combinedMaterial.defines ?? {};
                    combinedMaterial.defines.MAX_UBO_MATERIALS = getMaxMaterialsPerUBO(oldMaterial);
                    combinedMaterial.defines.MAX_UBO_FRAGMENTS = MAX_FRAGMENTS_PER_CONSOLIDATED_MESH;
                    combinedMaterial.defines.USE_UNIFORM_BUFFER_OBJECTS = true;
                }
                mesh.material = combinedMaterial;

                for (let mat of materials) {
                    this.#multiMaterialDrawingCombinedMaterialCache.set(mat, combinedMaterial);
                }
            } else {
                let newMaterial = materials[0];

                for (let key in newMaterial) {
                    if (newMaterial[key] !== mesh.material[key]) {
                        if (key === 'defines') continue;
                        if (key === 'id') continue;

                        mesh.material[key] = newMaterial[key];
                    }
                }
                mesh.material.needsUpdate = true;
            }
        }
    }

    #createMaterialBuffer(materials) {
        const materialType = materials[0].type;
        const maxMaterials = getMaxMaterialsPerUBO(materialType);

        // Create a new buffer to store the material data
        // We use the maximum number of materials per UBO instead of the actual number of materials as the size is baked into the shader
        // and having it dynamic would require a lot of shader permutations, materials etc.
        const materialBuffer = new Float32Array(maxMaterials * UBO_MATERIAL_SIZE[materialType]);

        switch (materialType) {
            case 'MeshPhongMaterial':
                this.#fillPhongMaterialBuffer(materials, materialBuffer, maxMaterials);
                break;
            case 'LineBasicMaterial':
                this.#fillLineBasicMaterialBuffer(materials, materialBuffer, maxMaterials);
                break;
        }
        return materialBuffer;
    }

    #fillPhongMaterialBuffer(materials, materialBuffer, maxMaterials) {
        for (let i = 0; i < materials.length; i++) {
            const color = materials[i].color;
            const shininess = materials[i].shininess;
            materialBuffer[i * 4 + 0] = color.r;
            materialBuffer[i * 4 + 1] = color.g;
            materialBuffer[i * 4 + 2] = color.b;
            materialBuffer[i * 4 + 3] = shininess;

            const specular = materials[i].specular;
            const opacity = materials[i].opacity;
            let offset = maxMaterials * 4;
            materialBuffer[offset + i * 4 + 0] = specular.r;
            materialBuffer[offset + i * 4 + 1] = specular.g;
            materialBuffer[offset + i * 4 + 2] = specular.b;
            materialBuffer[offset + i * 4 + 3] = opacity;

            const emissive = materials[i].emissive;
            const reflectivity = materials[i].reflectivity;
            offset += maxMaterials * 4;
            materialBuffer[offset + i * 4 + 0] = emissive.r;
            materialBuffer[offset + i * 4 + 1] = emissive.g;
            materialBuffer[offset + i * 4 + 2] = emissive.b;
            materialBuffer[offset + i * 4 + 3] = reflectivity;

            const ambient = materials[i].ambient;
            offset += maxMaterials * 4;
            materialBuffer[offset + i * 4 + 0] = ambient.r;
            materialBuffer[offset + i * 4 + 1] = ambient.g;
            materialBuffer[offset + i * 4 + 2] = ambient.b;
        }
    }

    #fillLineBasicMaterialBuffer(materials, materialBuffer, maxMaterials) {
        for (let i = 0; i < materials.length; i++) {
            const color = materials[i].color;
            materialBuffer[i * 4 + 0] = color.r;
            materialBuffer[i * 4 + 1] = color.g;
            materialBuffer[i * 4 + 2] = color.b;
            materialBuffer[i * 4 + 3] = materials[i].opacity;
        }
    }

    #updateUBOLimits(maxUniformBlockSize) {
        _maxUniformBlockSize = maxUniformBlockSize;
        MAX_FRAGMENTS_PER_CONSOLIDATED_MESH = Math.min(getMaxFragmentsPerUBO(), MAX_FRAGMENTS_PER_CONSOLIDATED_MESH);
    }
}

/**
 * Returns the number of materials that can be stored in a single UBO for the given material.
 * @param {THREE.Material} material
 * @returns Number of materials that can be stored in a single UBO.
 */
export function getMaxMaterialsPerUBO(material) {
    let maxSupportedMaterialsPerUBO = UBO_MATERIAL_SIZE[(material?.type ?? material)] ?? 0;
    return Math.min(maxSupportedMaterialsPerUBO, MAX_MATERIALS_PER_CONSOLIDATED_MESH);
}

function getMaxFragmentsPerUBO() {
    // data is stored in vectors of 4
    return Math.floor(_maxUniformBlockSize / 4);
}

/**
 * Test whether Uniform Buffer Objets (UBOs) can be used for the given material and number of fragments.
 * @param {THREE.Material} material
 * @param {number} numFragments
 * @returns {boolean} True if UBOs can be used.
 */
export function useUBOs(material, numFragments) {

    let useUBOs = USE_MULTI_MATERIAL_RENDER_CALLS && isSupportedUBOMaterial(material);
    useUBOs &&= MAX_FRAGMENTS_PER_CONSOLIDATED_MESH >= numFragments;

    return useUBOs;
}

/**
 * Test whether Uniform Buffer Objets (UBOs) are supported in general and for the given material.
 * @param {THREE.Material} material - The material to check.
 * @returns {boolean} True if UBOs are supported for this material.
 */
export function isSupportedUBOMaterial(material) {
    switch (material.type) {
        case 'MeshPhongMaterial':
        case 'LineBasicMaterial':
            return true;
        default:
            return false;
    }
}

/**
 * Tests whether the properties of two materials are compatible for use in the same UBO.
 * @param {THREE.Material} mat1
 * @param {THREE.Material} mat2
 * @returns
 */
function materialPropertiesCompatible(mat1, mat2) {
    let compatible = true;
    const ignoreAttributesForType = ignoreAttributes[mat1.type];
    for (let attrib of Object.keys(mat1)) {

        // TODO: Is there some better way to ignore irrelevant attributes?
        if (attrib[0] === '_' ||
            ignoreAttributes.Common.has(attrib) ||
            ignoreAttributesForType.has(attrib)
        )
            continue;

        if (!isEqual(mat1[attrib], mat2[attrib])) {
            compatible = false;
            break;
        }
    }

    return compatible;
}

export function materialsAreUBOCompatible(mat1, mat2, compatibleMaterialLUT) {
    if (mat1.id !== mat2.id) {
        // Ensure that the order of the materials is consistent so that we only need a one direction LUT
        if (mat1.id > mat2.id) {
            const tmp = mat1;
            mat1 = mat2;
            mat2 = tmp;
        }

        let compatible = compatibleMaterialLUT.get(mat1)?.get(mat2);
        if (compatible !== undefined) {
            return compatible;
        }

        if (!isSupportedUBOMaterial(mat1) || !isSupportedUBOMaterial(mat2) || mat1.type !== mat2.type) {
            return false;
        }

        compatible = materialPropertiesCompatible(mat1, mat2);

        let mat1Map = compatibleMaterialLUT.get(mat1);
        if (!mat1Map) {
            mat1Map = new Map();
            compatibleMaterialLUT.set(mat1, mat1Map);
        }
        mat1Map.set(mat2, compatible);

        return compatible;
    }

    return true;
}