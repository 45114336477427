/**
 * Imports for the main Autodesk.Viewing namespace
 */


//TODO_TS: stuff from compat/globas/envinit goes into both namespaces because they
//contain a mix of public and private exports,
//make it consistent in client code, then remove one of those
export * from "../compat";
export * from "../globals";
export { AutodeskNamespace } from '../module-prefix.js';

export {Hammer} from "./conditional/browser/hammer";

export * from "../envinit";

import i18n from "i18next";
i18n.translate = i18n.t.bind(i18n);
i18n.localize = () => {};
export {i18n};
export * from "../wgs/scene/SelectionMode";

//Common networking
export * from "../file-loaders/net/ErrorCodes";
export * from "../file-loaders/net/endpoints";

//Application layers
export * from "../application/FileLoaderManager";
export * from "../application/bubble";
export * from "../application/EventDispatcher";
export * from "../application/Extension";
export * from "../application/ExtensionManager";
export * from "../application/FileLoader";
export * from "../application/EventTypes";
export * from "../application/EventUtils";
export * from "../application/ScreenModeDelegate";
export * from "../application/Model";
export * from "../application/Document";
export * from "../application/Profile";
export * from "../application/ProfileSettings";
export * from "../application/Viewer3D";
export * from "../application/ModelMemoryTracker";
export * from "../application/ScreenShot";
export * from "../application/Thumbnails";
export * from "../application/AggregatedView";
export * from "../application/OverlayManager";
export * from "../application/CameraLS";
export * from "../application/ProfileManager";
export * from "../application/DynamicGlobalOffset";
export * from "../application/PropertySet";

export { FeatureFlags, PublicFeatureFlags } from "../application/FeatureFlags";
import * as _measurement_UnitFormatter from "../measurement/UnitFormatter";
export const ModelUnits = _measurement_UnitFormatter.ModelUnits;
export * from "../measurement/PDFUtils";

// Icky reconstruction of the MeasureCommon namespace from its interdependent modules.
export const MeasureCommon = {};
import * as measurement_MeasureCommon from '../measurement/MeasureCommon.js';
import * as measurement_MeasurementTypes from '../measurement/MeasurementTypes.js';
import * as measurement_SnapTypes from '../measurement/SnapTypes.js';
import * as measurement_MeasureEvents from '../measurement/MeasureEvents.js';
import * as measurement_Measurement from '../measurement/Measurement.js';
import * as measurement_SnapResult from '../measurement/SnapResult.js';

var _extend = Object.assign || function(target, source) {
    for (var prop in source) {
        if (Object.prototype.hasOwnProperty.call(source, prop)) {
            target[prop] = source[prop];
        }
    }
};

_extend(MeasureCommon, measurement_MeasureCommon);
MeasureCommon.MeasurementTypes = measurement_MeasurementTypes.MeasurementTypes;
MeasureCommon.MeasurementTypesToAnalytics = measurement_MeasurementTypes.MeasurementTypesToAnalytics;
MeasureCommon.SnapType = measurement_SnapTypes.SnapType;
MeasureCommon.Events = measurement_MeasureEvents.MeasureEvents;
MeasureCommon.Measurement = measurement_Measurement.Measurement;
MeasureCommon.SnapResult = measurement_SnapResult.SnapResult;

// GlobalManager
export * from "../application/GlobalManager";
export * from "../application/GlobalManagerMixin";

//Tools
export * from "../tools/Navigation";
export * from "../tools/UnifiedCamera";
export * from "../tools/SelectionType";
export * from "../tools/KeyCode";
export * from "../tools/ToolController";
export * from "../tools/HotkeyManager";
export * from "../tools/DefaultHandler";
export * from "../tools/ViewingUtilities";
export * from "../tools/GestureHandler";
export * from "../tools/ToolInterface";
export * from "../tools/OrbitDollyPanTool";
export * from "../tools/HotGestureTool";
export * from "../tools/FovTool";
export * from "../tools/WorldUpTool";
export * from "../tools/autocam/Autocam";

export * from "../application/ProgressState";
export * from "../mobile/MobileCallbacks";

// Conditionally import GUI components
export * from "./conditional/gui/av";

// Multi-Viewer
export * from "../leech-viewer/MultiViewerFactory";
export * from "../leech-viewer/LeechViewer";
export * from "../leech-viewer/CrossViewerInteractionCommon";

// Search
export * from "../search/SearchManager";

export * as Private from './avp.js';
export * as Extensions from './conditional/gui/ave.js';
export * as UI from './conditional/gui/avu.js';
export { isRightClick, isMiddleClick } from './avp.js';
