export default function(gantt) {
gantt.skins.skyblue = {
	config:{
		grid_width:370,
		row_height: 27,
		bar_height_padding:4,
		scale_height: 27,
		link_line_width:1,
		link_arrow_size:8,
		link_radius:2,
		lightbox_additional_height:75
	},
	_second_column_width:95,
	_third_column_width:80
};

};