import { digest } from './file-loaders/lmvtk/otg/HashStrings.js';

/**
 * Exctracts the Client ID from the access token
 *
 * @param {String} accessToken
 * @returns {String|undefined}
 */
function extractClientId(accessToken) {
  if (typeof accessToken !== 'string') return;

  const splitted = accessToken.split('.');
  if (splitted.length !== 3) return;

  const base64Url = splitted[1];
  try {
    const jsonPayload = Autodesk.Viewing.fromUrlSafeBase64(base64Url);
    const json = JSON.parse(jsonPayload);

    // only accept hashed client_id
    return json.client_id || '';
  } catch (e) {
    // We don't want to advertise this even more, so just silently ignore errors
  }
}

/**
 * Returns digest of the Client ID extracted from the access token
 *
 * @param {String} accessToken
 * @returns {Promise<String>} a promise that resolves to a valid Client ID or `'NOT_SET'`
 */
export async function getClientId(accessToken) {
  const clientId = extractClientId(accessToken);
  if (!clientId) {
    return 'NOT_SET';
  }
  const maskedClientId = await digest('SHA-256', clientId, true);
  return maskedClientId;
}
