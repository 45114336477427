import { BufferGeometry } from "three";
import { LineMaterial } from "../render/LineMaterial";

function getMaterialHash(material, hasTexures) {

	//Value that indicates the value has been set
	let mask = 0x01000000;

	//two bits
	if (material.depthWrite) {
		mask |= 0x1;
	}
	if (material.depthTest) {
		mask |= 0x2;
	}

	//three bits
	if (material.depthFunc) {
		switch (material.depthFunc) {
			case "less":          mask |= (1 << 2); break;
			case "less-equal":    mask |= (2 << 2); break;
			case "greater":       mask |= (3 << 2); break;
			case "greater-equal": mask |= (4 << 2); break;
			case "never":         mask |= (5 << 2); break;
			case "always":        mask |= (6 << 2); break;
			default: console.warn("unsupported depthFunc", material.depthFunc);
		}
	} else {
		//default is less-equal
		mask |= (2 << 2);
	}

	//two bits
	mask |= (material.side || 0) << 5;

	//one bit
	mask |= (hasTexures ? 1 << 7 : 0);

	//one bit
	mask |= ((material.isRoomMaterial && material.heatmapSensorCount) ? 1 << 8 : 0);

	// 6 bits left
	//NOTE: mask payload cannot use more than 15 bits, because of the way we use it in getPipelineHash below
	//If we need more bits, to encode e.g. more shader macro states, then getPipelineHash has to change.

	material.__gpuPipelineHash = mask;
	return mask;
}

function getBufferLayoutHash(geometry, includeNormals, includeUV, includeVC) {

	//TODO: currently set up to only memorize one configuration, so includeUV and includeVC cannot
	//vary across uses of the geometry. This can be an issue if a geometry is used from both a textured
	//and non-textured pipeline, for example, but for now is avoided by the caller by always configuring
	//the pipeline in the same way (i.e. the pipeline also depends on the includeUV/VC flag).

	// Note: geometry.__gpuPipelineHash only encodes the geometry buffer layout, but it's combined into a single flag
	// with the material mask in getPipelineHash, so we can only use the first 17 bits (15 bits are used for the
	// materials mask).
	let mask = geometry.__gpuPipelineHash;
	if (mask) {
		return mask;
	}

	// 8 bits
	mask = geometry.vbstride & 0xff; //assumes it's < 256 floats wide

	const attributes = geometry.attributesInterleaved || geometry.attributes;
	for (let key in attributes) {
		let attr = attributes[key];
		switch (key) {

			// 1 bit
			case "position": {
				if (attr.itemOffset !== 0) {
					console.log("unexpected itemOffset");
				}
				mask |= 1 << 8;
			}
				break;

			//TODO: non-packed normals
			// 2 bits
			case "normal": {
				if (attr.itemOffset !== 3) {
					console.log("unexpected itemOffset");
				}
				if (attr.bytesPerItem === 2) {
					mask |= 1 << 9;
				} else {
					mask |= 2 << 9;
				}
			}
				break;

			// 2 bits
			case "uv":
			case "uvw": {
				if (includeUV) {
					if (attr.itemOffset === 3) {
						mask |= 1 << 11;
					} else if (attr.itemOffset === 4) {
						mask |= 2 << 11;
					} else if (attr.itemOffset === 6) {
						mask |= 3 << 11;
					} else {
						console.log("unexpected itemOffset for color channel", attr.itemOffset);
					}
				}
			}
				break;

			// 3 bits
			case "color": {
				if (includeVC) {
					if (attr.itemOffset === 3) {
						mask |= 1 << 13;
					} else if (attr.itemOffset === 4) {
						mask |= 2 << 13;
					} else if (attr.itemOffset === 6) {
						mask |= 3 << 13;
					} else if (attr.itemOffset === 8) {
						mask |= 4 << 13;
					} else {
						console.log("unexpected itemOffset");
					}
				}
			}
				break;
		}
	}

	// 1 bit
	let geomType = 0;
	if (geometry.isLines) {
		geomType = 1;
	}
	mask |= geomType << 16;

	geometry.__gpuPipelineHash = mask;

	return mask;
}

export function getPipelineHash(geometry, material, includeNormals, includeUV, includeVC, hasTextures) {

	let geomHash = geometry.__gpuPipelineHash;
	if (!geomHash) {
		geomHash = getBufferLayoutHash(geometry, !geometry.isLines && includeNormals, includeUV, includeVC);
	}

	let matHash = material.__gpuPipelineHash;
	if (material.needsUpdate || !matHash) {
		matHash = getMaterialHash(material, hasTextures);
	}

	return geomHash | (matHash << 17);
}

export function getBufferLayout(geometry, includeNormals, includeUV, includeVC, isUVW) {
	let buffers = [];

	let hasVertexColor = includeVC && geometry.attributes.color;

	if (geometry.vb) {
		let layout = {
			arrayStride: geometry.vbstride * 4,
			attributes: []
		};

		const attributes = geometry.attributesInterleaved || geometry.attributes;
		if ('position' in attributes) {
			layout.attributes.push({
				shaderLocation: 0,
				offset: attributes.position.itemOffset * 4,
				format: "float32x3"
			});
		}
		//TODO: non-packed normals
		if (!geometry.isLines && includeNormals) {
			layout.attributes.push({
				shaderLocation: 1,
				offset: attributes.normal.itemOffset * 4,
				format: "uint32"
			});
		}

		if (includeUV) {
			if (isUVW) {
				layout.attributes.push({
					shaderLocation: hasVertexColor ? 3 : 2,
					offset: attributes.uvw.itemOffset * 4,
					format: "float32x3"
				});
			} else {
				layout.attributes.push({
					shaderLocation: hasVertexColor ? 3 : 2,
					offset: attributes.uv.itemOffset * 4,
					format: "float32x2"
				});
			}
		}
		if (includeVC) {
			layout.attributes.push({
				shaderLocation: 2,
				offset: attributes.color.itemOffset * 4,
				format: "float32x3"
			});
		}

		buffers.push(layout);
	} else {
		console.log("non-interleaved buffer geometry not yet done");
	}

	return buffers;
}



function getBufferLayoutHash2D(geometry) {
	geometry.__gpuPipelineHash = geometry.vbstride;
	return geometry.__gpuPipelineHash;
}

export function getBufferLayout2D(geometry) {

	let isCompact = false;

	let buffers = [];

	let layout = {
		arrayStride: geometry.vbstride * 4,
		attributes: []
	};

	if (isCompact) {

		//TODO: only wide layout supported for WebGPU at the moment

	} else {
		//From VertexBufferBuilder
		/*
		"fields1" :    { offset: 0,                   itemSize: 2, bytesPerItem: 4, divisor: d, normalized: false },
		"fields2" :    { offset: 2,                   itemSize: 4, bytesPerItem: 4, divisor: d, normalized: false },
		"color4b":     { offset: VBB_COLOR_OFFSET,    itemSize: 4, bytesPerItem: 1, divisor: d, normalized: true  },
		"dbId4b":      { offset: VBB_DBID_OFFSET,     itemSize: 4, bytesPerItem: 1, divisor: d, normalized: false },
		"flags4b":     { offset: VBB_FLAGS_OFFSET,    itemSize: 4, bytesPerItem: 1, divisor: d, normalized: false },
		"layerVp4b":   { offset: VBB_LAYER_VP_OFFSET, itemSize: 4, bytesPerItem: 1, divisor: d, normalized: false }
		 */

		//fields1
		layout.attributes.push({
			shaderLocation: 0,
			offset: 0,
			format: "float32x2"
		});

		//fields2
		layout.attributes.push({
			shaderLocation: 1,
			offset: 8,
			format: "float32x4"
		});

		//color4b
		layout.attributes.push({
			shaderLocation: 2,
			offset: 24,
			format: "unorm8x4"
		});

		//dbId4b
		layout.attributes.push({
			shaderLocation: 3,
			offset: 28,
			format: "uint8x4"
		});

		//flags4b
		layout.attributes.push({
			shaderLocation: 4,
			offset: 32,
			format: "uint8x4"
		});

		//layerVp4b
		layout.attributes.push({
			shaderLocation: 5,
			offset: 36,
			format: "uint8x4"
		});


		// Needed for elliptical arcs support in the Line shader.
		// Set the expanded vertex layout to use the last two floats in the buffer. If they
		// were allocated then it is good. If they weren't it overlaps the flags4b and layerVp4b
		// channels, but since the extraParams won't be used in the shader it won't matter.
		// Doing this lets the shader connect to something and prevents crashes on iOS.
		//mesh.vblayout["extraParams"] = { offset: this.stride - 2, itemSize: 2, bytesPerItem: 4, divisor: d, normalized: false };
		// extraParams
		layout.attributes.push({
			shaderLocation: 6,
			offset: layout.arrayStride - 8,
			format: "float32x2",
		});
	}


	buffers.push(layout);

	return buffers;
}


/**
 * @typedef {object} MaterialVariations
 * @property {boolean} materialHasLineStyles
 * @property {boolean} isSelectionHighlight
 */

/**
 * @param {LineMaterial} material
 * @returns {MaterialVariations}
 */
export function getMaterialVariations2D(material) {
	return {
		materialHasLineStyles: !!material.hasLineStyles,
		isSelectionHighlight: !!material.defines["SELECTION_RENDERER"],
	};
}

const MATERIAL_2D_HASH_BITS = {
	materialHasLineStyles: 1 << 0,
	isSelectionHighlight: 1 << 1,
};

/**
 * @param {BufferGeometry} geometry
 * @param {LineMaterial} material
 * @returns {number}
 */
export function getPipelineHash2D(geometry, material) {
	let geomHash = geometry.__gpuPipelineHash;
	if (geomHash === undefined) {
		geomHash = getBufferLayoutHash2D(geometry);
	}

	let matHash = material.__gpuPipelineHash;
	if (material.needsUpdate || matHash === undefined) {
		matHash = 0;

		const variations = getMaterialVariations2D(material);
		if (variations.materialHasLineStyles) {
			matHash |= MATERIAL_2D_HASH_BITS.materialHasLineStyles;
		}
		if (variations.isSelectionHighlight) {
			matHash |= MATERIAL_2D_HASH_BITS.isSelectionHighlight;
		}

		material.__gpuPipelineHash = matHash;
	}

	return geomHash | (matHash << 24);
}
