import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";

import pack_depth from "../chunks/pack_depth.wgsl";
import colorShader from "./color.wgsl";
import groundShadowShader from "./ground_shadow.wgsl";
import blurFragment from "./blur_fragment.wgsl";

import { getObjectUniformsDeclaration } from "../main/uniforms/ObjectUniforms";
import { getTextureDeclaration } from "./GroundShadowTextures";
import { FrameBindGroup } from "../main/FrameBindGroup";

export function getGroundShadowShader() {
	return $wgsl(groundShadowShader, {
		frameBindGroup: FrameBindGroup.getDeclaration(0),
		objectUniforms: getObjectUniformsDeclaration(1),
		pack_depth,
	});
}

export function getBlurFragmentShader(horizontal, textureBindingIndex) {
	return $wgsl(blurFragment, {
		pack_depth,
		groundShadowTexture: getTextureDeclaration(textureBindingIndex),
		horizontal,
	});
}

export function getColorShader() {
	return $wgsl(colorShader, {
		frameBindGroup: FrameBindGroup.getDeclaration(0),
		groundShadowTexture: getTextureDeclaration(1),
		pack_depth
	});
}
