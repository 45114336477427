import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { UniformBuffer } from "../UniformBuffer";
import { LineMaterial } from "../../render/LineMaterial";
import { Renderer} from "../Renderer";
import { initTexture } from "../Texture";
import { MipmapPipeline } from "../main/MipmapPipeline";
import lineMaterialUniforms from "./line_material_uniforms.wgsl";

export class LineMaterialManager {
    /**
     * @param {number} group
     */
    static getUniformsDeclaration(group) {
        return $wgsl(lineMaterialUniforms, { group });
    }

    /** @type {GPUDevice} */
    #device;
    /** @type {TextureInfo} */
    #defaultTexture;
    /** @type {MipmapPipeline} */
    #mipmapPipeline;
    /** @type {GPUBindGroupLayout} */
    #layout;

    #boundUpdate;
    #boundDispose;

    // Only used as a placeholder in case uniforms.selectionColor is not specified.
    #defaultSelectionColor = new THREE.Vector4(0, 0, 1, 1);

    /**
     * @param {Renderer} renderer
     */
    constructor(renderer) {
        this.#device = renderer.getDevice();
        this.#defaultTexture = renderer.getPlaceholderTexture();
        this.#mipmapPipeline = new MipmapPipeline(this.#device);

        this.#layout = this.#device.createBindGroupLayout({
            entries: [
                {
                    binding: 0,
                    visibility: GPUShaderStage.FRAGMENT | GPUShaderStage.VERTEX,
                    buffer: {}
                },
                {
                    binding: 1,
                    visibility: GPUShaderStage.FRAGMENT,
                    texture: {
                        sampleType: 'float',
                    }
                },
                {
                    binding: 2,
                    visibility: GPUShaderStage.FRAGMENT,
                    sampler: {}
                },
                {
                    binding: 3,
                    visibility: GPUShaderStage.FRAGMENT | GPUShaderStage.VERTEX,
                    texture: {
                        sampleType: 'float',
                    }
                }
            ]
        });

        this.#boundUpdate = this.handleUpdate.bind(this);
        this.#boundDispose = this.handleDispose.bind(this);
    }

    /**
     * @returns {GPUBindGroupLayout}
     */
    getLayout() {
        return this.#layout;
    }

    /**
     * @param {LineMaterial} material
     */
    initMaterial(material) {
        if (!material.__buffer) {
            material.__buffer = new UniformBuffer(
                this.#device, 8, /*visibleInFragment=*/true, /*visibleInVertex=*/false);
        }

        let view;
        let sampler;

        const texture = material.uniforms["tRaster"].value;
        if (texture) {
            initTexture(this.#device, this.#mipmapPipeline, texture);
            texture.__gpuView = texture.__gpuTexture.createView();

            // hasTexture = true
            material.__buffer.setInt(4, 1);
            view = texture.__gpuView;
            sampler = texture.__gpuSampler;
        } else {
            // hasTexture = false
            material.__buffer.setInt(4, 0);
            view = this.#defaultTexture.view;
            sampler = this.#defaultTexture.sampler;
        }

        const selectionColor = material.uniforms.selectionColor?.value || this.#defaultSelectionColor;
        material.__buffer.setVector4(0, selectionColor);

        let selectionTextureView = null;
        const selectionTex = material.uniforms["tSelectionTexture"]?.value;
        if (selectionTex) {
            // Actually, selectionTexture is usually never mipmapped and mipmapPipeline will not be needed.
            // Just passing here because we have it anyway and don't need to make implicit assumptions.
            initTexture(this.#device, this.#mipmapPipeline, selectionTex);
            selectionTextureView = selectionTex.__gpuTexture.createView();
        } else {
            selectionTextureView = this.#defaultTexture.view;
        }

        material.__buffer.upload();
        material.__gpuBindGroup = this.#createBindGroup(
            material.__buffer.getBuffer(), view, sampler, selectionTextureView);

        material.needsUpdate = false;

        if (!material.hasEventListener('dispose', this.#boundDispose)) {
            material.addEventListener('dispose', this.#boundDispose);
        }

        if (!material.hasEventListener('update', this.#boundUpdate)) {
            material.addEventListener('update', this.#boundUpdate);
        }
    }

    updateTextures(material) {
        const texture = material.uniforms["tRaster"].value;
        if (texture && texture.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, texture);
        }

        const selectionTex = material.uniforms["tSelectionTexture"]?.value;
        if (selectionTex && selectionTex.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, selectionTex);
        }
    }

    /**
     * @param {Object} event
     * @param {LineMaterial} event.target
     */
    handleUpdate(event) {
        this.initMaterial(event.target);
    }

    /**
     * @param {Object} event
     * @param {LineMaterial} event.target
     */
   handleDispose(event) {
       const material = event.target;
       material.removeEventListener('dispose', this.#boundDispose);

        if (material.__buffer) {
            material.__buffer.getBuffer()?.destroy();
            material.__buffer = null;
        }

        const texture = material.uniforms["tRaster"].value;
        if (texture) {
            texture.__gpuView = null;
            texture.dispose();
        }
    }

    /**
     * @param {GPUBuffer} buffer
     * @param {GPUTextureView} view
     * @param {GPUSampler} sampler
     * @returns {GPUBindGroup}
     */
    #createBindGroup(buffer, view, sampler, selectionTextureView) {
        return this.#device.createBindGroup({
            layout: this.#layout,
            entries: [
                {
                    binding: 0,
                    resource: {
                        buffer,
                    }
                },
                {
                    binding: 1,
                    resource: view,
                },
                {
                    binding: 2,
                    resource: sampler,
                },
                {
                    binding: 3,
                    resource: selectionTextureView,
                }
            ]
        });
    }
}
