import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import groundShadowTexture from "./ground_shadow_texture.wgsl";

export function getTextureDeclaration(bindGroup) {
	return $wgsl(groundShadowTexture, { bindGroup });
}

export function getTextureBindGroupLayout(device) {
	return device.createBindGroupLayout({
		label: 'ground shadow texture bind group layout',
		entries: [
			{
				binding: 0,
				visibility: GPUShaderStage.FRAGMENT,
				texture: {
					sampleType: 'float'
				}
			},
			{
				binding: 1,
				visibility: GPUShaderStage.FRAGMENT,
				sampler: {}
			}
		]
	});
}

export function getTextureBindGroup(device, layout, sampler1, view1, label = 'ground shadow texture bind group') {
	return device.createBindGroup({
		label,
		layout,
		entries: [
			{ binding: 0, resource: view1 },
			{ binding: 1, resource: sampler1 }
		]
	});
}
