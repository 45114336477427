

// Shared WebGPU device used by default for viewer initializations if WebGPU is enabled and available
let _defaultWebGPUDevice = null;
let _nestedInits = 0; // Allow nesting of InitWebGPU calls


/**
 * Creates a WebGPU device.
 * @param {GPURequestAdapterOptions} gpuRequestAdapterOptions Passed to navigator.gpu.requestAdapter
 */
export async function createWebGPUDevice(gpuRequestAdapterOptions = {}) {
    const defaultOptions = {
        powerPreference: "high-performance"
    };
    const options = Object.assign({}, defaultOptions, gpuRequestAdapterOptions);

	const adapter = await navigator?.gpu?.requestAdapter(options);

    if (!adapter) {
        console.warn("Couldn't request WebGPU adapter. WebGPU will not be available.");
        return;
	}

    console.log(adapter.info);

	const device = await adapter.requestDevice({});
	console.log(device.limits);

	return device;
}

/**
 * Creates a shared WebGPU device that is used by default for viewer initializations if WebGPU is toggled on.
 * This makes sure we keep it if different components (e.g. different ACC clients in Exoskeleton are accessing it)
 * @param {GPURequestAdapterOptions} gpuRequestAdapterOptions Passed to navigator.gpu.requestAdapter
 *
 * @experimental WebGPU functionality is currently experimental.
 */
export async function InitWebGPU(gpuRequestAdapterOptions) {
    _nestedInits++;
    if (!_defaultWebGPUDevice) {
        _defaultWebGPUDevice = await createWebGPUDevice(gpuRequestAdapterOptions);
        return _defaultWebGPUDevice;
    }
}

export function ShutDownWebGPU() {
    _nestedInits--;
    if (_nestedInits === 0) {
        _defaultWebGPUDevice.destroy();
        _defaultWebGPUDevice = null;
    }
}

export function GetDefaultWebGPUDevice() {
    return _defaultWebGPUDevice;
}
