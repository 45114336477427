import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'

// // multilanguage context
import { LanguageContext } from './context/multilingual'

import './scss/style.scss'

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse' />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App () {
  const [language, setLanguage] = useState('spanish')

  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0]
    browserLanguage === 'es' ? setLanguage('spanish') : setLanguage('english')
  }, [])

  function toggleLanguage () {
    setLanguage((language) => (language === 'spanish' ? 'english' : 'spanish'))
  }

  return (
    <>
      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path='/login' name='Login Page' element={<Login />} />
              <Route exact path='/register' name='Register Page' element={<Register />} />
              <Route exact path='/404' name='Page 404' element={<Page404 />} />
              <Route exact path='/500' name='Page 500' element={<Page500 />} />
              <Route path='*' name='Home' element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </LanguageContext.Provider>
    </>
  )
}

export default App
