import sum from 'lodash/sum';
import { RemainingFragmentsTask } from './tasks/RemainingFragmentsTask';
import { InstancedMeshUploadTask } from './tasks/InstancedMeshUploadTask';
import { USE_WEBGPU } from '../../globals';
import { OutOfCoreTileManager } from './OutOfCoreTileManager';

/** @import { RenderModel } from "../RenderModel" */
/** @import { OutOfCoreTaskBase } from "./tasks/OutOfCoreTaskBase" */

/**
 * @typedef BvhNodeIteratorState
 * Describes the state of a BVH node for a specific viewer
 *
 * @property {number} screenSpaceError - The screen space error of the node
 * @property {number} lastUpdated - The frame count when the node was last updated
 * @property {number} lastRendered - The frame count when the node was last rendered
 */

export class BvhNode {
    nodeId;

    // We track the state for the different iterators separately
    // (because each viewpoint shows a different POV and thus the nodes have
    //  different screen space errors)
    /** @type {BvhNodeIteratorState[]} */ iteratorStates = [];
    lockedCounter = 0;
    transparent = false;
    initialized = false;

    /** @type {OutOfCoreTaskBase[]} */ remainingTasks = [];
    /** @type {OutOfCoreTaskBase[]} */ processedTasks = [];
    /** @type {RenderModel} */ model;

    /** @type {Number} - Amount of resource independent tasks this node has. This is an optimization to prevent unnecessary iterations */
    #numResourceIndependentTasks = 0;

    /**
     * Represents a node in the BVH
     * @param {number} nodeId
     * @param {RenderModel} model
     * @param {OutOfCoreTileManager} outOfCoreTileManager
     */
    constructor(nodeId, model, outOfCoreTileManager) {
        this.nodeId = nodeId;
        this.model = model;
        this.completelyLoaded = false;
        this.outOfCoreTileManager = outOfCoreTileManager;
        this.initialized = false;
    }

    /**
     * Returns the RemainingFragmentsTask of the node
     * @returns {RemainingFragmentsTask|undefined}
     */
    getRemainingFragmentsTask() {
        return this.remainingTasks.find(task => task instanceof RemainingFragmentsTask) ??
            this.processedTasks.find(task => task instanceof RemainingFragmentsTask);
    }

    /**
     * Returns the InstancedMeshUploadTask of the node
     * @returns {InstancedMeshUploadTask|undefined}
     */
    getInstancedMeshUploadTask() {
        return this.remainingTasks.find(task => task instanceof InstancedMeshUploadTask) ??
            this.processedTasks.find(task => task instanceof InstancedMeshUploadTask);
    }

    /**
     * Adds a new task to the node
     *
     * @param {OutOfCoreTaskBase} task
     */
    addTask(task) {
        this.remainingTasks.push(task);

        if (task.isResourceIndependent) {
            this.#numResourceIndependentTasks++;
            OutOfCoreTileManager.numResourceIndependentTasks++;
        }
    }

    /**
     * Check whether the task already exists in the node
     *
     * @param {Number} meshIndex Index of the mesh we are looking for
     * @returns boolean
     */
    hasTask(meshIndex) {
        return this.remainingTasks.some(task => task.meshIndex === meshIndex);
    }

    /**
     * Check whether the task already exists in the node
     *
     * @param {Number} meshIndex Index of the mesh we are looking for
     * @returns boolean
     */
    hasInstanceTask(geom) {
        return this.remainingTasks.some(task => task.geom === geom) ||
            this.processedTasks.some(task => task.geom === geom);
    }

    hasNextTask() {
        return this.remainingTasks.length > 0;
    }

    /**
     * Process the next task in the queue
     * @returns {[number, OutOfCoreTaskBase]} - Returns the memory cost of the task and the task itself
     */
    processNextTask() {
        const task = this.remainingTasks.shift();
        const consumedMemory = task.execute();

        // if the task is one shot it will be discarded
        if (!task.isOneShot) {
            this.processedTasks.push(task);
        }

        if (task.isResourceIndependent) {
            this.#numResourceIndependentTasks--;
            OutOfCoreTileManager.numResourceIndependentTasks--;
        }

        return [consumedMemory, task];
    }

    hasResourceIndependentTasks() {
        return this.#numResourceIndependentTasks > 0;
    }

    /**
     * Process the next resource independent task in the queue
     */
    processNextResourceIndependentTask() {
        for (let i = 0; i < this.remainingTasks.length; i++) {
            const task = this.remainingTasks[i];

            if (task.isResourceIndependent) {
                task.execute();

                this.remainingTasks.splice(i);

                // if the task is one shot it will be discarded
                if (!task.isOneShot) {
                    this.processedTasks.push(task);
                }

                this.#numResourceIndependentTasks--;
                OutOfCoreTileManager.numResourceIndependentTasks--;
                return;
            }
        }
    }

    /**
     * @returns {boolean} Whether the next task will fit in the GPU staging buffer.
     */
    nextTaskWillFit() {
        if (!USE_WEBGPU) {
            return true;
        }

        if (!this.remainingTasks.length) {
            return true;
        }

        const needed = this.remainingTasks[0].getMemoryCost();
        return this.outOfCoreTileManager.getRenderer().canUpload(needed);
    }

    /**
     * Get memory cost for this node
     *
     * @returns {number} The total GPU memory cost when uploading the node to the GPU
     */
    getTotalMemoryCost() {
        return this.getRemainingMemoryCost() + this.getCurrentMemoryCost();
    }

    /**
     * Get remaining memory cost for this node
     *
     * @returns {number} The GPU memory cost for processing the remaining tasks for this node
     */
    getRemainingMemoryCost() {
        return sum(this.remainingTasks.map(x => x.getMemoryCost()));
    }

    /**
     * Get the current memory cost for this node
     *
     * @returns {number} The GPU memory currently consumed by this node
     */
    getCurrentMemoryCost() {
        return sum(this.processedTasks.map(x => x.getMemoryCost()));
    }

    /**
     * Returns the memory that can be freed by this node
     * @param {Object} scratchpad - Used to share information with other tasks to accurately determine the memory that can be freed
     * @returns
     */
    getFreeableMemory(scratchpad) {
        let freeableMemory = 0;
        for (let task of this.processedTasks) {
            freeableMemory += task.getFreeableMemory(scratchpad);
        }
        return freeableMemory;
    }

    /**
     * Free the memory allocated for the consolidation of this node
     * @returns {number} freed memory in bytes
     */
    freeMemory() {
        let freedMemory = 0;
        for (let task of this.processedTasks) {
            freedMemory += task.freeMemory();
        }

        this.remainingTasks.push(...this.processedTasks);
        this.processedTasks = [];

        return freedMemory;
    }

    /**
     * Calculates the current screen space error.
     *
     * In contrast to the screenspace getter, this function takes the time of the last
     * update into account and uses 0 as screenspace error if the node has not been updated for a while.
     *
     * It returns the maximum screen space error of all iterators.
     *
     * @returns {number} The current screen space error.
     */
    getCurrentScreenSpaceError() {
        let screenSpaceError = -Infinity;

        for (let i = 0; i < this.iteratorStates.length; i++) {
            screenSpaceError = Math.max(screenSpaceError, this._getIteratorScreenSpaceError(i));
        }

        return screenSpaceError;
    }

    /**
     * Computes the screen space error for a given iterator
     * @param {number} iteratorId
     * @returns {number}
     */
    _getIteratorScreenSpaceError(iteratorId) {
        const currentFrame = this.outOfCoreTileManager.getFrameCount(iteratorId);

        const state = this.iteratorStates[iteratorId];

        // If this node has never been updated for the given iterator, we return -Infinity
        // as the lowest possible screen space error
        if (!state) {
            return -Infinity;
        }

        // In the following code we use a few heuristics to take into account that we
        // won't always have the most recent screen space error available. Updating the
        // screenspace error happens only when the corresponding render batch is traversed
        // in the BVH traversal. So there might be render batches that have not been traversed
        // in the last frame. We don't want to throw those away right away, because due to jitter,
        // it could happen that they are needed in the next frame again. As a heuristic, we decide
        // after 10 frames without an update of the screenspace error, it is no longer valid and
        // return 0. Additionally, we consider a node that hasn't been rendered for 10 frames stale
        // and also return 0. This heuristic might change, once we perform updates of the screen space
        // error in the background independent from rendering.

        const screenSpaceError = state.screenSpaceError;
        if (currentFrame - state.lastRendered < 10) {
            return screenSpaceError;
        } else {
            const framesSinceUpdate = currentFrame - state.lastUpdated;
            return framesSinceUpdate < 10 ? screenSpaceError : 0;
        }
    }

    /**
     * Updates the screen space error for a given iterator ID.
     * @param {number} iteratorId - The ID of the iterator.
     * @param {number} screenSpaceError - The new screen space error value.
     * @param {number} lastUpdated - The frame number of the last update.
     */
    updateScreenSpaceError(iteratorId, screenSpaceError, lastUpdated) {
        if (this.iteratorStates[iteratorId] === undefined) {
            this.iteratorStates[iteratorId] = {
                screenSpaceError: screenSpaceError,
                lastUpdated: lastUpdated,
                lastRendered: lastUpdated
            };
        }

        this.iteratorStates[iteratorId].screenSpaceError = screenSpaceError;
        this.iteratorStates[iteratorId].lastUpdated = lastUpdated;
    }

    /**
     * Updates the last rendered frame number for the specified iterator.
     *
     * @param {string} iteratorId - The ID of the iterator.
     * @param {number} lastRendered - The frame number when the last render occurred.
     */
    updateLastRendered(iteratorId, lastRendered) {
        if (this.iteratorStates[iteratorId] === undefined) {
            this.iteratorStates[iteratorId] = {
                screenSpaceError: Infinity,
                lastUpdated: lastRendered,
                lastRendered: lastRendered
            };
        }

        this.iteratorStates[iteratorId].lastRendered = lastRendered;
    }

    /**
     * Compares two nodes based on their screen space error (also taking into account the loaded
     * flag and the transparency flag, sorting transparent objects behind opaque objects)
     *
     * @param BvhNode} other
     * @returns {number} - Returns negative value if this node has a higher screen space error, positive value if the
     *                     other node has a higher screen space error and 0 if they are equal
     */
    compare(other) {
        // Make sure that completely loaded nodes are processed first
        if (this.completelyLoaded !== other.completelyLoaded) {
            return this.completelyLoaded ? -1 : 1;
        }
        if (this.transparent != other.transparent) {
            return this.transparent ? 1 : -1;
        }
        return other.getCurrentScreenSpaceError() - this.getCurrentScreenSpaceError();
    }
}
