
//This file is the first one when creating the bundled build and
//initializes some global namespaces and other global variables.
//Once the code is fully modular, the creation of the global namespace
//can/should be moved to the module-suffix instead.

import { getGlobal } from './global';

/**
 * Create namespace
 * @param {string} s - namespace (e.g. 'Autodesk.Viewing')
 * @return {Object} namespace
 */
export function AutodeskNamespace(s) {
    var ns = getGlobal();

    var parts = s.split('.');
    for (var i = 0; i < parts.length; ++i) {
        ns[parts[i]] = ns[parts[i]] || {};
        ns = ns[parts[i]];
    }

    return ns;
}

const Autodesk = AutodeskNamespace("Autodesk");
export default { Autodesk };

// Define the most often used ones
AutodeskNamespace("Autodesk.Viewing.Private");

AutodeskNamespace("Autodesk.Viewing.Extensions");
AutodeskNamespace("Autodesk.Extensions"); // Webpack bundled extensions.

AutodeskNamespace("Autodesk.Viewing.Shaders");

AutodeskNamespace('Autodesk.Viewing.UI');

AutodeskNamespace('Autodesk.LMVTK');

getGlobal().AutodeskNamespace = AutodeskNamespace;

// We initialize this flag already here in the module-prefix file to make sure
// it is set before any other initialization code is executed.
getGlobal().ENABLE_DEBUG = globalThis.ENABLE_DEBUG ?? globalThis.Autodesk?.Viewing?.Private?.ENABLE_DEBUG ?? false;

let alreadyLoggedVars = new Set();

export function adsk_export(exports, m, ns, skip) {
    for (let prop in m) {
        if (skip && skip.indexOf(prop) !== -1) {
            continue;
        }
        if (Object.prototype.hasOwnProperty.call(m, prop)) {
            // Export directly into the module (e.g. for node.js use, where LMV is used via require instead from global namespace)
            // For symbols that are literals (not objects), we assign a getter to make sure we see updates of the original value.
            let exportSymbol = function(obj) {
                if (!(m[prop] instanceof Object)) {
                    Object.defineProperty(obj, prop, {
                        get() {
                            return m[prop];
                        },
                        set(newValue) {
                            if (m["set" + prop]) {
                                m["set" + prop](newValue);
                            } else {
                                if (!alreadyLoggedVars.has(prop)) {
                                    Autodesk.Viewing.Private.logger.error("No setter for global variable " + prop + " defined. It is read only.");
                                    alreadyLoggedVars.add(prop);
                                }
                            }
                        },
                        enumerable: true,
                        configurable: true,
                    });
                } else {
                    obj[prop] = m[prop];
                }
            };

            exportSymbol(exports);

            //Export into the desired viewer namespace
            if (ns)
                exportSymbol(exports[ns]);
        }
    }
}
