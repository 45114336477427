
import {DepthFormat} from "../CommonRenderTargets";
import {getBufferLayout2D, getPipelineHash2D, getMaterialVariations2D} from "../Pipelines";
import {Renderer} from "../Renderer";
import {VertexBuffer} from "../VertexBuffer";
import {BufferGeometry} from "three";
import {LineMaterial} from "../../render/LineMaterial";
import {LineMaterialManager} from "./LineMaterialManager";
import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getObjectUniformsDeclaration} from "../main/uniforms/ObjectUniforms";
import {getLineUniformsDeclaration} from "./LineUniforms";
import hatch from "../chunks/hatch_pattern.wgsl";
import lineShader from "./lineSS.wgsl";
import {FrameBindGroup} from "../main/FrameBindGroup";

export class LinePipeline {
	/** @type {Renderer} */
	#renderer;
	/** @type {GPUDevice} */
	#device;

	/** @type {Map.<number, GPURenderPipeline>} */
	#pipelines = new Map();
	/** @type {GPUBindGroupLayout[]} */
	#bindGroupLayouts;
	/** @type {GPURenderPipeline>|null} */
	#activePipeline = null;
	/** @type {LineMaterial|null} */
	#activeMaterial = null;
	/** @type {GPUColorTargetState[]} */
	#activeTargetsList;
	/** @type {VertexBuffer} */
	#vb;
	/** @type {LineMaterialManager} */
	#lineMatMan;

	/**
	 * @param {Renderer} renderer
	 */
	constructor(renderer) {
		this.#renderer = renderer;
		this.#device = renderer.getDevice();
		this.#vb = this.#renderer.getVB();
		this.#lineMatMan = new LineMaterialManager(renderer);
	}

	/**
	 * @param {LineMaterial} material
	 * @returns {string}
	 */
	static #getShader(material) {
		return $wgsl(lineShader, {
			frameBindGroup: FrameBindGroup.getDeclaration(0),
			objectUniforms: getObjectUniformsDeclaration(1),
			lineUniforms: getLineUniformsDeclaration(2),
			lineMaterialUniforms: LineMaterialManager.getUniformsDeclaration(3),
			hatch,
			...getMaterialVariations2D(material),
		});
	}

	/**
	 * @param {number} key
	 * @param {BufferGeometry} geometry
	 * @param {LineMaterial} material
	 * @returns {GPURenderPipeline}
	 */
	#createPipeline(key, geometry, material) {

		let pipeline;

		let shader = this.#device.createShaderModule({
			code: LinePipeline.#getShader(material),
		});

		pipeline = this.#device.createRenderPipeline({
			layout: this.#device.createPipelineLayout({
				bindGroupLayouts: this.#bindGroupLayouts
			}),
			vertex: {
				module: shader,
				entryPoint: 'vsmain',
				buffers: getBufferLayout2D(geometry),
			},
			fragment: {
				module: shader,
				entryPoint: 'psmain',
				targets: this.#activeTargetsList,
			},
			primitive: {
				topology: 'triangle-list',
				cullMode: 'none',
			},

			depthStencil: {
				depthWriteEnabled: material.depthWrite,
				depthCompare: material.depthTest ? (material.depthFunc || 'less-equal') : "always",
				format: DepthFormat,
			},
		});

		this.#pipelines.set(key, pipeline);

		return pipeline;
	}

	/**
	 * @param {GPUColorTargetState[]} targets
	 */
	reset(targets) {
		this.#activePipeline = null;
		this.#activeTargetsList = targets;
		this.#activeMaterial = null;
	}

	/**
	 * @param {GPUBindGroupLayout} frameLayout
	 * @param {GPUBindGroupLayout} objectLayout
	 * @param {GPUBindGroupLayout} lineLayout
	 */
	setLayouts(frameLayout, objectLayout, lineLayout) {
		this.#bindGroupLayouts = [
			frameLayout,
			objectLayout,
			lineLayout,
			this.#lineMatMan.getLayout()
		];
	}

	/**
	 * @param {GPURenderPassEncoder} passEncoder
	 * @param {GPUBindGroup} frameBindGroup
	 * @param {GPUBindGroup} objectBindGroup
	 * @param {GPUBindGroup} lineBindGroup
	 */
	setBindGroups(passEncoder, frameBindGroup, objectBindGroup, lineBindGroup) {
		passEncoder.setBindGroup(0, frameBindGroup);
		passEncoder.setBindGroup(1, objectBindGroup);
		passEncoder.setBindGroup(2, lineBindGroup);
	}

	/**
	 * @param {GPURenderPassEncoder} passEncoder
	 * @param {number} objectIndex
	 * @param {BufferGeometry} geometry
	 * @param {LineMaterial} material
	 */
	drawOne(passEncoder, objectIndex, geometry, material) {

		const key = getPipelineHash2D(geometry, material);

		let pipeline = this.#pipelines.get(key);
		if (!pipeline) {
			pipeline = this.#createPipeline(key, geometry, material);
		}

		if (pipeline !== this.#activePipeline) {
			passEncoder.setPipeline(pipeline);
			this.#activePipeline = pipeline;
			this.#activeMaterial = null;
		}

		if (material.needsUpdate || !material.__gpuBindGroup) {
			this.#lineMatMan.initMaterial(material);
		}

		// Make sure raster and selection textures are up-to-date
		this.#lineMatMan.updateTextures(material);

		if (material !== this.#activeMaterial) {
			passEncoder.setBindGroup(3, material.__gpuBindGroup);
			this.#activeMaterial = material;
		}

		this.#vb.draw(passEncoder, geometry, objectIndex);
	}

}
