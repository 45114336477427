import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getEnvMapSampleChunk} from "../chunks/env_sample";
import tonemap from "../chunks/tonemap.wgsl";
import envMapShader from './env-map.wgsl';

export function getEnvMapShader(isGammaEncoded, tonemapMethod) {
    return $wgsl(envMapShader, {
        tonemap,
        envMapSample: getEnvMapSampleChunk(isGammaEncoded, !isGammaEncoded, tonemapMethod > 0),
        tonemapMethod1: tonemapMethod === 1,
        tonemapMethod2: tonemapMethod === 2
    });
}
