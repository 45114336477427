import i18n from "i18next";

export function ProgressBar(container, showLabel = false) {

    let _document = container.ownerDocument;

    this.bg = _document.createElement('div');
    this.bg.className = 'progressbg';

    this.fg = _document.createElement('div');
    this.fg.className = 'progressfg';

    this.bg.appendChild(this.fg);
    container.appendChild(this.bg);

    this.lastValue = -1;

    this.fg.style.transform = `scale(0, 1)`;

    this.labels = [];
    this.showLabel = showLabel;

    // Add labels for the states from ProgressStates.js
    this.registerLabel(container, 'ProgressLoading');    // ROOT_LOADED
    this.registerLabel(container, 'ProgressLoading');    // LOADING
    this.registerLabel(container, 'ProgressRendering');  // RENDERING
    this.registerLabel(container, 'ProgressOptimizing'); // OPTIMIZING
}

ProgressBar.prototype.registerLabel = function(container, text) {
    const label = container.ownerDocument.createElement('div');
    label.className = 'progresslb';
    label.style.opacity = "0";
    label.setAttribute("data-i18n", text);
    label.textContent = i18n.t(text);

    this.labels.push(label);
    container.appendChild(label);
};

ProgressBar.prototype.setPercent = function(pct, state) {

    if (pct == this.lastValue)
        return;

    this.lastValue = pct;

    if (pct >= 100) {
        this.bg.style.opacity = "0";
        this.labels.forEach((label) => label.style.opacity = "0");
    } else {
        this.bg.style.opacity = "1";
        this.fg.style.transform = `scale(${pct / 100}, 1)`;
        if (this.showLabel) {
            this.labels.forEach((label, labelState) => label.style.opacity = state === labelState ? "1" : "0");
        }
    }
};

ProgressBar.prototype.setStyle = function(style) {
    !this.fg.classList.contains(style) && this.fg.classList.add(style);
};

ProgressBar.prototype.removeStyle = function(style) {
    this.fg.classList.contains(style) && this.fg.classList.remove(style);
};
