import { DepthFormat, NormalBlend } from "../CommonRenderTargets";

import { getColorShader } from "./GroundShadowShaders";

export class GroundShadowColorPipeline {

	#renderer;
	#device;

	#pipeline;

	#bindGroupLayouts = [];
	#targets = [];

	constructor(renderer, frameLayout, textureLayout, targetFormat, geomStride) {
		this.#renderer = renderer;
		this.#device = renderer.getDevice();

		this.#bindGroupLayouts = [
			frameLayout,
			textureLayout,
		];

		this.#targets.push({
			format: targetFormat,
			blend: NormalBlend
		});


		const shader = this.#device.createShaderModule({
			label: 'ground shadow color shader',
			code: getColorShader(),
		});

		this.#pipeline = this.#device.createRenderPipeline({
			label: 'ground shadow color pipeline',
			layout: this.#device.createPipelineLayout({
				bindGroupLayouts: this.#bindGroupLayouts
			}),
			vertex: {
				module: shader,
				entryPoint: 'vsmain',
				buffers: [
					{
						arrayStride: geomStride * 4,
						attributes: [
							{
								shaderLocation: 0, // pos
								offset: 0,
								format: "float32x3"
							},
							{
								shaderLocation: 1, // uv
								offset: 12,
								format: "float32x2"
							},
						]
					}
				]
			},
			fragment: {
				module: shader,
				entryPoint: 'psmain',
				targets: this.#targets,
			},
			primitive: {
				cullMode: 'back',
				topology: 'triangle-list',
			},
			depthStencil: {
				depthWriteEnabled: false,
				depthCompare: 'less',
				format: DepthFormat,
				depthBias: 0,
				depthBiasSlopeScale: 0
			},
		});
	}

	getPipeline() {
		return this.#pipeline;
	}
}
