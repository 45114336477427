import { USE_WEBGPU } from '../../../globals';
import {OutOfCoreTileManager } from '../OutOfCoreTileManager';
import { OutOfCoreTaskBase } from './OutOfCoreTaskBase';

/** @import { FragmentList } from "../../FragmentList" */

/**
 * Base class for geometry upload tasks
 */
export class UploadTaskBase extends OutOfCoreTaskBase {
    /** @type {number|undefined} */ #memoryCost;

    /**
     * Creates a new task to upload meshes to the GPU
     *
     * @param {OutOfCoreTileManager} outOfCoreTileManager - The OutOfCoreTileManager instance
     * @param {FragmentList} fragList - The fragment list
     * @param {number} bvhNodeId - The BVH node ID
     */
    constructor(outOfCoreTileManager, fragList, bvhNodeId) {
        super(outOfCoreTileManager);
        this.fragList = fragList;
        this.bvhNodeId = bvhNodeId;
    }

    #isReady() {
        if (USE_WEBGPU) {
            return true;
        }

        const model = this.outOfCoreTileManager.model;
        const consolidation = model.getConsolidation();
        return Boolean(consolidation);
    }

    /**
     * Returns the geometries to be uploaded
     * @returns {THREE.BufferGeometry[]} The geometries to be uploaded
     */
    getGeometries() {

    }

    /**
     * Uploads the geometries to the GPU
     *
     * @returns {number} The memory cost of the uploaded geometry.
     */
    execute() {
        if (!this.#isReady()) {
            return 0;
        }

        let renderer = this.outOfCoreTileManager.getRenderer();
        let geometries = this.getGeometries();

        let memoryCost = 0;
        for (let geom of geometries) {
            let currentRefCount = OutOfCoreTileManager.geomRefCounts.get(geom) ?? 0;

            // Uploaded the mesh to the GPU
            if (geom.streamingDraw === true) {
                geom.streamingDraw = false;
                geom.streamingIndex = false;

                renderer.uploadGeometry(geom);

                memoryCost += geom.getAccurateByteSize();
            }

            OutOfCoreTileManager.geomRefCounts.set(geom, currentRefCount + 1);
        }

        return memoryCost;
    }

    /**
     * Frees memory associated with the instanced geometry.
     * @returns {number} The amount of memory freed in bytes.
     */
    freeMemory() {
        if (!this.#isReady()) {
            return 0;
        }

        let geometries = this.getGeometries();

        let freedMemory = 0;
        for (let geom of geometries) {
            let currentRefCount = (OutOfCoreTileManager.geomRefCounts.get(geom) ?? 0) - 1;
            if (currentRefCount === 0) {
                OutOfCoreTileManager.geomRefCounts.delete(geom);

                if (geom.streamingDraw !== true) {
                    geom.dispose();
                    geom.streamingDraw = true;
                    geom.streamingIndex = true;

                    freedMemory += geom.getAccurateByteSize();
                }
            } else {
                OutOfCoreTileManager.geomRefCounts.set(geom, currentRefCount);
            }
        }

        return freedMemory;
    }

    /**
     * Get the memory cost for uploading the remaining fragments of the mesh
     *
     * @returns {number} The memory cost in bytes.
     */
    getMemoryCost() {
        if (this.#memoryCost === undefined) {
            if (!this.#isReady()) {
                return 0;
            }

            let geometries = this.getGeometries();

            this.#memoryCost = 0;
            for (let geom of geometries) {
                this.#memoryCost += geom.getAccurateByteSize();
            }
        }

        return this.#memoryCost;
    }

    /**
     * Returns the memory that can be freed by this task
     * @param {Object} scratchpad - Used to share information with other tasks to accurately determine the memory that can be freed
     * @returns
     */
    getFreeableMemory(scratchpad) {
        if (!this.#isReady()) {
            return 0;
        }

        let freeableMemory = 0;

        let geometries = this.getGeometries();

        for (let geom of geometries) {

            scratchpad.freedRemainingGeometriesCounts = scratchpad.freedRemainingGeometriesCounts ?? new Map();
            let geomFreedCount = scratchpad.freedRemainingGeometriesCounts.get(geom) ?? 0;
            geomFreedCount++;
            scratchpad.freedRemainingGeometriesCounts.set(geom, geomFreedCount);

            let refCount = OutOfCoreTileManager.geomRefCounts.get(geom) - geomFreedCount;

            if (refCount === 0) {
                freeableMemory += geom.getAccurateByteSize();
            }

        }
        return freeableMemory;
    }
}
